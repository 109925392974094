import React from "react";
import { Link } from "react-router-dom";
import newsStor from "../../../Store/News";
import defStor from "../../../Store/Default"
import { observer } from "mobx-react-lite";
import "./WidgetNews.scss";
const WidgetNews = observer(() => {
 
  if(defStor.getLang()===2){
    const news = newsStor.getLastNewsEng(3);
    const newsItems = news.map((item) => {
      return (
        <div className="wn_block" key={item.date + item.title}>
          <div className="wn_block_date">{item.date}</div>
          <div className="wn_block_title">{item.title}</div>
        </div>
      );
    });
    return(
      <div className="wn_body">
      <div className="wn_header">Important</div>
      <div className="wn_title">News and events</div>
      {newsItems}
      <Link to="/news" style={{ textDecoration: "none" }}>
        <div className="row" style={{ marginTop: "34px" }}>
          <div className="wn_link_text">All news</div>
          <div className="wn_link_img">
            <svg
              style={{ paddingLeft: "15px", marginBottom: "-1px" }}
              width="30"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                stroke="#E82862"
                strokeWidth="1.5"
              />
            </svg>
          </div>
        </div>
      </Link>
    </div>
    )
  }
  const news = newsStor.getLastNews(3);
  const newsItems = news.map((item) => {
    return (
      <div className="wn_block" key={item.date + item.title}>
        <div className="wn_block_date">{newsStor.getDtString(item.date)}</div>
        <div className="wn_block_title">{item.title}</div>
      </div>
    );
  });
  return (
    <div className="wn_body">
      <div className="wn_header">Важное</div>
      <div className="wn_title">Новости и события</div>
      {newsItems}
      <Link to="/news" style={{ textDecoration: "none" }}>
        <div className="row" style={{ marginTop: "34px" }}>
          <div className="wn_link_text">Все новости</div>
          <div className="wn_link_img">
            <svg
              style={{ paddingLeft: "15px", marginBottom: "-1px" }}
              width="30"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                stroke="#E82862"
                strokeWidth="1.5"
              />
            </svg>
          </div>
        </div>
      </Link>
    </div>
  );
});
export default WidgetNews;
