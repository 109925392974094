import React, { useState } from "react";
import Chart from "../Chart/Chart";
import MenuRight from "../MenuRight/MenuRight";
import "./ImageChartMenu.scss";
const ImageChartMenu = ({
  titleImg = "",
  textImg = "",
}: {
  titleImg?: string;
  textImg?: string;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      id="page_content_img_menu"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div id="page_content_info_img">
        <Chart notTransparent={isHovering} />
        <svg
          className="rectangle22"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M32 32V0L0 32H32Z" fill="white" />
        </svg>
      </div>

      <div className="page_menu">
        <MenuRight />
      </div>
      <div
        id="page_content_img_text"
        style={{
          transition: "visibility 0s, opacity 0.5s linear",
          ...(isHovering
            ? { visibility: "hidden", opacity: "0" }
            : { visibility: "visible", opacity: "1" }),
        }}
      >
        <div
          style={{
            width: "50%",
            height: "520px",
            display: "flex",
            flexDirection: "row-reverse",
            position: "absolute",
            left: "0",
            top: "0",
          }}
        >
          <div style={{ width: "720px" }}>
            <div style={{ marginLeft: "-240px", position: "relative" }}>
              <svg
                className="group26"
                width="107"
                height="107"
                viewBox="0 0 107 107"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g style={{ mixBlendMode: "hard-light" }}>
                  <circle cx="1.5" cy="1.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="1.5" cy="27.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="1.5" cy="53.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="1.5" cy="79.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="1.5" cy="105.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="27.5" cy="1.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="27.5" cy="27.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="27.5" cy="53.5" r="1.5" fill="#E82862" />
                  <circle cx="27.5" cy="79.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="27.5" cy="105.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="53.5" cy="1.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="53.5" cy="27.5" r="1.5" fill="#E82862" />
                  <circle cx="53.5" cy="53.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="53.5" cy="79.5" r="1.5" fill="#E82862" />
                  <circle cx="53.5" cy="105.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="79.5" cy="1.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="79.5" cy="27.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="79.5" cy="53.5" r="1.5" fill="#E82862" />
                  <circle cx="79.5" cy="79.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="79.5" cy="105.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="105.5" cy="1.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="105.5" cy="27.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="105.5" cy="53.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="105.5" cy="79.5" r="1.5" fill="#E9E9E9" />
                  <circle cx="105.5" cy="105.5" r="1.5" fill="#E9E9E9" />
                </g>
              </svg>
              <div className="line33"></div>
              <div
                className="title_img"
                dangerouslySetInnerHTML={{ __html: titleImg }}
              ></div>
              <div
                className="text_img"
                dangerouslySetInnerHTML={{ __html: textImg }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="page_content_img_hline"
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
          left: "0",
          height: "90px",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ width: "50%" }}></div>
          <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
            <div style={{ flex: "0 30px" }}>
              <svg
                width="30"
                height="90"
                viewBox="0 0 30 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30 60L30 60L0 90"
                  stroke="#E0E0E0"
                  strokeDasharray="8 6"
                />
              </svg>
            </div>
            <div style={{ flex: "1 auto", overflow: "hidden" }}>
              <div>
                <svg
                  width="2000"
                  height="90"
                  viewBox="0 0 2000 90"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 60L0 60L2000 60"
                    stroke="#E0E0E0"
                    strokeDasharray="8 6"
                  />
                </svg>
              </div>
            </div>
            <div style={{ flex: "0 60px" }}>
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M60 0L60 0L0 60"
                  stroke="#E0E0E0"
                  strokeDasharray="8 6"
                />
              </svg>
            </div>
            <div style={{ flex: "0 400px" }}>
              <div style={{ width: "400px" }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ImageChartMenu;
