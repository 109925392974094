import React from "react";
import "./StartTrade.scss";
import Header from "../../Components/Header/Header";
import ImageChartMenu from "../../Components/ImageChartMenu/ImageChartMenu";
import Footer from "../../Components/rus/Footer/Footer";
import { VideoInstructionsItem } from "../../Components/rus/VideoInstructions/VideoInstructions";

import storDef from "../../Store/Default"
import { observer } from "mobx-react-lite";

const StartTrade = observer( () => {
  let ls=storDef.getLang()
  if( ls===2){
    return (
      <div className="body">
        <Header />
  
        <ImageChartMenu
          titleImg="How to start trading on AE"
          textImg="
          1. Register in AE Trade or AE Game, get an access key to the trading terminal
          <br />2. Install the trading terminal, or open the web terminal
          <br />3. Connect to the exchange server and start trading"
        />
  
        <div className="page_content">
          <div className="page_content_left">
            <VideoInstructionsItem
              title="How to open an account on the AE"
              url="https://player.vimeo.com/video/737064600?h=e5f8f43f4d"
            />
          </div>
          <div className="page_content_vline"></div>
          <div className="page_content_right">
           </div>
        </div>
        <div className="page_footer">
          <Footer />
        </div>
      </div>
    );
  }
  return (
    <div className="body">
      <Header />

      <ImageChartMenu
        titleImg="Как начать торговать на AE"
        textImg="Для подключения к торгам биржи :<br />
                1. Зарегистрируйтесь в AE Trade или AE Game, получите ключ доступа к торговому терминалу
                <br />2.
                Установите торговый терминал, либо откройте веб терминал
                <br />3. Подключитесь к серверу биржи и начинайте торговлю"
      />

      <div className="page_content">
        <div className="page_content_left">
          <VideoInstructionsItem
            title="Как открыть торговый счет"
            url="https://player.vimeo.com/video/735907994?h=a99e9a4243"
          />
        </div>
        <div className="page_content_vline"></div>
        <div className="page_content_right">
          <VideoInstructionsItem
            title="Знакомство с торговым терминалом"
            url="https://www.youtube.com/embed/dzdu_rMVe34"
          />
        </div>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
})

export default StartTrade;
