import {makeAutoObservable} from 'mobx'

class Default {
  selectedLang=2

    constructor() {
      makeAutoObservable(this);
    }
    setLang=(i:number)=>{
      this.selectedLang=i;
    }
    getLang=()=>{
      return this.selectedLang
    }

}

export default new Default()