import React from "react";
import storeDef from "../../../Store/Default"
import "./VideoInstructions.scss";

const VideoInstructions = () => {
  const videosRus: { header?: string; title?: string; url: string }[] = [
    {
      header: "Промо",
      title: "О бирже",
      url: "https://player.vimeo.com/video/680751735?h=6e7e22b5e4",
      //title: "Как получить доступ к AE",
      // url: "https://www.youtube.com/embed/-xwN3ySyeZE",
      //url: "https://www.youtube.com/embed/kTgmZqpBbdg",
      //url: "https://www.youtube.com/embed/9X9YfUxJVLE",
    },
    {
      title: "Как открыть торговый счет",
      url: "https://player.vimeo.com/video/735907994?h=a99e9a4243",
    },
  ];
  const videosEngl: { header?: string; title?: string; url: string }[] =  [
    {
      title: "Promo",
      url: "https://player.vimeo.com/video/705701512?h=28004e1ef2",
    //  title: "How to access AE",
    //  url: "https://www.youtube.com/embed/-xwN3ySyeZE",
      //url: "https://www.youtube.com/embed/9X9YfUxJVLE",
    },
    {
      header:"Video instruction",
      title: "How to open an account on the AE",
      url: "https://player.vimeo.com/video/737064600?h=e5f8f43f4d",
    },
  ]
  let videos=videosRus;
  if(storeDef.getLang()===2){
    videos=videosEngl;
  }
  const videoInsItem = videos.map((item) => {
    return (
      <VideoInstructionsItem
        header={item.header}
        title={item.title}
        url={item.url}
        key={item.url}
      ></VideoInstructionsItem>
    );
  });
  return (
    <div>
      <div>{videoInsItem}</div>
    </div>
  );
};
export const VideoInstructionsItem = ({
  header,
  title,
  url,
}: {
  header?: string;
  title?: string;
  url: string;
}) => {
  return (
    <div className="vi_body">
      <div className="vi_header">{header===''?'Видео-инструкция': header}</div>
      <div className="vi_title">{title}</div>

      <div className="vi_video">
        <iframe
          src={url}
          width="100%"
          height="315"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      <div className="vi_points"></div>
    </div>
  );
};
export default VideoInstructions;
