import { makeAutoObservable } from "mobx";
import axios from "axios";
//import { Centrifuge } from "centrifuge";
import { connect as natsConnect, JSONCodec } from "nats.ws";
interface Instrument {
  FullName: string;
  Ticker: string;
  Type: string;
  Derived: Instrument[];
  ExpDate: number;
}
interface FutureItem {
  Ticker: string;
  Last: number;
  Direction: string;
  Iconame: string;
}
class Instruments {
  constructor() {
    makeAutoObservable(this);
  }
  CentrifugoPrefix = "";
  SelectedFuture="";
  token = "";
  centrifuge: any = {};
  nats: any = {};
  fitems: FutureItem[] = [];
  setSelectedFuture(ticker:string){
    this.SelectedFuture=ticker
  }
  ChangeFitemsLast(data: FutureItem) {
//    console.log(data);
    this.fitems.forEach((v, i) => {
      if (v.Ticker === data.Ticker) {
        if (v.Last !== 0) {
          if (v.Last > data.Last) {
            this.fitems[i].Direction = "down";
          }
          if (v.Last < data.Last) {
            this.fitems[i].Direction = "up";
          }
        }
        this.fitems[i].Last = data.Last;
        if (data.Iconame !== "") {
          this.fitems[i].Iconame = data.Iconame;
        }
        //   setFuturesItems(fitems)
      }
    });
  }
  async SubscribeInstruments(Instruments: Instrument[]) {
    let ar: string[] = [];
    let t = this;
    Instruments.forEach(function (v) {
      let fitem = { Ticker: v.Ticker, Last: 0, Direction: "", Iconame: "" };

      t.fitems.push(fitem);
      /*
      let sb = t.centrifuge.newSubscription(
        "instruments:aetrade" + v.Ticker
      );

      sb.on("publication", function (ctx: any) {
        if (ctx.data.Last != null) {
          t.ChangeFitemsLast({ Ticker: ctx.data.Ticker, Last: ctx.data.Last, Direction:"", Iconame:"" });
        }
      });
      sb.subscribe();*/
      t.MyNatsSubscribe("TRADE.instruments." + v.Ticker, t.FeedResponse);
      ar.push(v.Ticker);
    });
    const res = await axios.post(
      "https://ae.exchange/lktrade/users/instruments",
      { tickers: ar }
    );
    if (res.data.code === 0) {
      let instr = res.data.data.Instruments;
      //   console.log(instr)
      instr.forEach((v: any) => {
        let fn = v.Instrument.FullName;
        let fna = fn.split(" ");
        let fi: FutureItem = {
          Ticker: v.Instrument.Ticker,
          Last: v.quote.Last,
          Direction: "",
          Iconame: fna[0],
        };
        t.ChangeFitemsLast(fi);
      });
    }
  }
  FeedResponse(ctx: any, t: any) {
    if (ctx.Ticker !== undefined) {
      //   console.log(t)
      if (ctx.Last != null) {
        t.ChangeFitemsLast({
          Ticker: ctx.Ticker,
          Last: ctx.Last,
          Direction: "",
          Iconame: "",
        });
      }
    }
  }
  getFutures(instruments: Instrument[]) {
    let futures: Instrument[] = [];
    let t = this;
    instruments.forEach(function (v) {
      if (v.Type === "Futures") {
        //  console.log(v.Type);
        const fut: Instrument = {
          FullName: v.FullName,
          Type: v.Type,
          Ticker: v.Ticker,
          ExpDate: v.ExpDate,
          Derived: [],
        };
        futures.push(fut);
        if (t.SelectedFuture===""){
          t.SelectedFuture=fut.Ticker
        }
      } else {
        if (v.Derived.length > 0) {
          let futs = t.getFutures(v.Derived);
          if (futs.length > 0) {
            futures = futures.concat(futs);
          }
        }
      }
    });
    return futures;
  }

  async loadInstruments() {
    const res = await axios.get(
      "https://ae.exchange/lktrade/users/instruments/all"
    );

    if (res.data.code === 0) {
      const instruments = res.data.data.Boards[0].Instruments;
      let futs = this.getFutures(instruments);
      // console.log(futs);
      this.SubscribeInstruments(futs);
    }
  }

  async Loads() {
    /* const res = await axios.post(
      "https://ae.exchange/lktrade/users/loginUser",
      {
        login: "aesite",
        pass: "aesite",
      }
    );
    if (res.data.code === 0) {
      this.CentrifugoPrefix = res.data.data.CentrifugoPrefix;
      this.token = res.data.data.Token;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + res.data.data.Token;
      const res2 = await axios.get("https://ae.exchange/lktrade/users/user");
      if (res2.data.code === 0) {
        this.centrifuge = new Centrifuge(
          "wss://ae.exchange/centrifugov4/connection/websocket?cf_protocol_version=v2",
          {
            token: res2.data.data.Centri,
          }
        );
        this.centrifuge.connect();*/
    this.NatsConnecting();
    this.loadInstruments();
    //    }
    //  }
  }
  async NatsConnecting() {
    const v = { servers: "wss://ae.exchange/njs" };
    try {
      this.nats = await natsConnect(v);
      //const done = this.nats.closed();
      // close the connection
      /*  await this.nats.close();
    // check if the close was OK
    const err = await done;
    if (err) {
      console.log(`error closing:`, err);
    }*/
    } catch (err) {
      console.log(`error connecting to ${JSON.stringify(v)}`);
    }
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async MyNatsSubscribe(channel: string, callbackfunction: any) {
    const t = this;
    while (this.nats === undefined) {
      await this.delay(100);
    }
    while (this.nats.subscribe === undefined) {
      await this.delay(100);
    }
    const ch = this.nats.subscribe(channel);
    const jc = JSONCodec();

    for await (const m of ch) {
      callbackfunction(jc.decode(m.data), t);
    }
  }
  getInstruments = () => {
    return this.fitems;
  };
}

export default new Instruments();
