import React from "react";
import "./Onbidding.scss";
import Header from "../../Components/Header/Header";
import ImageChartMenu from "../../Components/ImageChartMenu/ImageChartMenu";
import Footer from "../../Components/rus/Footer/Footer";
import storDef from "../../Store/Default";
import { observer } from "mobx-react-lite";

const Onbidding = observer(() => {

  const infosRus ={ 
    titleImg:"Биржа деривативов",
    textImg:"Торги на бирже АЕ проходят с центральным контрагентом как на классических мировых биржах, в режиме анонимных сделок, матчинг сделок осуществляется в соответствии с очередью подачи ордеров при выполнение справедливых условий исполнения цены по лучшим ценам bid или ask.",
    text:[
    {
      title: "Фьючерсы",
      text: "Фьючерс расчетного типа, беспоставочный. Тикер фьючерса <br/>например BTСM22.AE, где BTС первые буквы тикера индекса <br/>на который обращается фьючерс, M месяц экспирации, 22 две <br/>последнии цифры года экспирации фьючерса, AE торговая <br/>площадка. <br/>Подробнее смотрите спецификации фьючерсов",
    },
    {
      title: "Финансовые расчеты по торговому счету",
      text: "Расчеты PnL производятся в режиме непрерывного клиринга, <br/>при котором оценка позиций по торговому счету <br/>пересчитывается при изменению цены фьючерса и <br/>теоретических цен опционов. Расчеты производятся в валюте <br/>USDT. Лимит торгового счета Money ( устанавливается в USDT) <br/>равен сумме оценки залогового обеспечения BTC по индексу <br/>BTCUSDT.AE плюс количество USDT. <br/> <br/>Размер маржинальных требований для обеспечения открытых <br/>позиций и активных ордеров блокируются из средств лимита <br/>Money отображаются в поле GM по торговому счету.",
    },
    {
      title: "Опционы",
      text: "Опционы ванильные (биржевые), европейского типа, <br/>поставочные. Тикер опциона например BTС50000CM22, где <br/>BTС первые буквы тикера базового инструмента (фьючерса), <br/>50000 цена страйк опциона, С тип опциона Call (P соотв Put), M <br/>месяц экспирации, 22 две последнии цифры года экспирации <br/>опциона. У недельных опционов в конце тикера добавляется <br/>буква W и цифра недели экспирации в месяце <br/> <br/>Подробнее смотрите спецификации опционов",
    },
    {
      title: "Экспирации фьючерсов и опционов",
      text: "<b>Фьючерсы.</b> Исполнение фьючерсов производится на дату <br/>экспирации в 8.00 UTC по расчетной цене экспирации. <br/> <b>Опционы.</b>  Исполнение опционов происходит в день <br/>экспирации в 8.00 UTC, подача поручений на исполнение не <br/>требуется, автоматически исполняются все опционы в деньгах <br/>(на 1 пункт цены страйк и более) с расчетом по цене страйк. В <br/>результате экспирации производятся системные сделки по <br/>поставке фьючерсов.",
    },
  ]};
  const infosEng ={ 
    titleImg:"Derivatives exchange",
    textImg:"Trading on the AE exchange is held with a central counterparty as on<br />classical world exchanges, in the mode of anonymous transactions, the <br />matching of transactions is carried out in accordance with the order <br />submission queue if the fair price execution conditions are fulfilled at the <br> best bid or ask prices.",
    text:[
    {
      title: "Futures",
      text: "Settlement type futures, non-deliverable. Futures ticker for example       <br />BTСM22.AE, where BTС is the first letters of the ticker of the index      <br />to which the futures are circulated, M is the expiration month, 22 is      <br />the last two digits of the futures expiration year, AE trading      <br />exchange.      <br />See futures specifications for details",
    },
    {
      title: "Financial settlements on a trading account",
      text: "PnL calculations are performed in the continuous clearing mode, in      <br>which the valuation of positions on the trading account is      <br>recalculated when the futures price and theoretical options prices      <br>change. Calculations are made in USDT currency. The Money      <br>trading account limit (set in USDT) is equal to the sum of the BTC      <br>collateral assessment according to the BTCUSDT.AE index plus the      <br>amount of USDT.      <br>      <br>The amount of margin requirements to ensure open positions and      <br>active orders are blocked from the funds of the Money limit are      <br>displayed in the GM field for the trading account.",
    },
    {
      title: "Options",
      text: "Options are vanilla (exchange), European type, deliverable. Option      <br>ticker, for example BTС50000CM22, where BTС is the first letters of      <br>the ticker of the underlying instrument (futures), 50,000 is the strike      <br>price of the option, С is the type of Call option (P is Put), M is the      <br>expiration month, 22 is the last two digits of the option expiration      <br>year. For weekly options, at the end of the ticker, the letter W and      <br>the number of the expiration week in the month are added.      <br>See options specifications for details.",    
    },
    {
      title: "Expirations of futures and options",
      text: "<b>Futures.</b> Futures are executed on the expiry date at 8.00 UTC at the      <br>estimated expiry price.      <br>      <b>Options.</b> The options are exercised on the expiration day at 8.00 UTC,      <br>no execution orders are required, all options in money (for 1 point of the      <br>strike price or more) are automatically exercised at the strike price. As a      <br>result of expiration, systemic transactions for the delivery of futures are      <br>made.",    
    },
  ]};
  let info = infosRus;
  let ls=storDef.getLang()

  if( ls===2){
    info=infosEng;
  }
  return (
    <div className="body">
      <Header />

      <ImageChartMenu
        titleImg={info.titleImg}
        textImg={info.textImg}
      />

      <div className="page_content">
        <div className="page_content_left">
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[0].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[0].text }}
          ></div>
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[1].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[1].text }}
          ></div>
        </div>
        <div className="page_content_vline"></div>
        <div className="page_content_right">
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[2].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[2].text }}
          ></div>
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[3].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[3].text }}
          ></div>
        </div>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
});

export default Onbidding;
