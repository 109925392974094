import React from "react";
import "./Club.scss";
import Header from "../../Components/Header/Header";
import ImageChartMenu from "../../Components/ImageChartMenu/ImageChartMenu";
import Footer from "../../Components/rus/Footer/Footer";

import storDef from "../../Store/Default";
import { observer } from "mobx-react-lite";

const Club = observer(() => {
  let ls = storDef.getLang();
  if (ls === 2) {
    return (
      <div className="body">
        <Header />

        <ImageChartMenu titleImg="" textImg="" />

        <div className="page_content">
          <div className="page_content_left"></div>
          <div className="page_content_vline"></div>
          <div className="page_content_right"></div>
        </div>
        <div className="page_footer">
          <Footer />
        </div>
      </div>
    );
  }
  return (
    <div className="body">
      <Header />

      <ImageChartMenu titleImg="Клуб Опцион LAFT" textImg="" />

      <div className="page_content">
        <div className="page_content_left">
          <div className="h_content" style={{ marginTop: "30px" }}>
            Клуб "Опцион LAFT"
          </div><br />
          объединяет единомышленников торговли опционами на бирже с
          образовательными, финансовыми и инвестиционными целями.
          <br />
          Команда клуба: Сергей Елисеев основатель терминала Option-lab и биржи
          крипто деривативов АЕ, опытные опционные трейдеры и специалисты
          команды LAFT.
          <br />
          <div className="h_content" style={{ marginTop: "30px" }}>
            Участник клуба получает доступы и сервисы:
          </div>
          <br />
          + Закрытая группа Telegram
          <br />
          + Еженедельные вебинары с обзором опционных и алго стратегий
          <br />
          + Тематические вебинары по продвинутым темам обучения торговли
          опционами и алго трейдинга
          <br />
          + Разборы торговых стратегий участников с рекомендациями по управлению
          и контролю за рисками
          <br />
          + Торговая и пользовательская поддержка в группе телеграм клуба
          <br />
          + Расширенный пакет торговых роботов
          <br />
          + Доступ к библиотеке вебинаров клуба в закрытом Telegram канале клуба
          <br />
          <br />
          <br />
          <div className="h_content" style={{ marginTop: "30px" }}>
            Условия участия в клубе 
          </div>
          <br />
          Первичная подписка 9 АЕ. Подписка в клуб полугодовая (6 месяцев).
          Стоимость продления подписки 7 АЕ . Ветеранам клуба стоимость продления 5
      АЕ. (Ветеран - член клуба более года).
          <br />
        </div>
        <div className="page_content_vline"></div>
        <div className="page_content_right">
        <div className="h_content" style={{ marginTop: "30px" }}>
          Правила участия в клубе :</div> <br />
          - Недопустимо выражение неуважения и оскорбления в отношении
          администрации или участников клуба.
          <br />
          - Нельзя заниматься продвижением в клубе собственных или сторонних
          услуг или сервисов. <br />
          - Запрещены публикации на ресурсах клуба политической, религиозной и
          др информации вне тематики трейдинга и криптовалют.
          <br />
          - Запрещена передача полученной в клубе информации и материалов
          третьим лицам.
          <br />
          За нарушения правил клуба следует исключение без возврата оплаты за
          подписку.
          <br />
          Администрация клуба оставляет за собой право отказа в принятии заявки
          на подписку.
          <br />
          <br />
          <div className="h_content" style={{ marginTop: "30px" }}>
          Заявку на подписку</div><br />
           в клуб "Опцион LAFT" можно отправить в Личном
          кабинете. Вопросы по участию клубе направляйте client@ae.exchange
        </div>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
});

export default Club;
