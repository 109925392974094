import React from 'react';
import {  Link } from "react-router-dom";
import "./Logo.scss"
const Logo=()=>{
    return(
        <Link to="/" >
        <div id="logo_group">
          <div id="logo_ae-png2"></div>
          <div id="logo_line32"></div>
          <div id="logo_al-ex">
            Alternative
            Exchange
          </div>
        </div>
      </Link>
    )
}
export default Logo