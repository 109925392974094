import React from "react";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import storDef from "../../Store/Default"
import "./MenuRight.scss";
const MenuRight = () => {
  const location = useLocation();
//  const params = useParams();
const menuItemsEng:{
  title: string;
  text: string;
  url: string;
  selected: boolean;
}[]= [
 /* {
    title: "AE Game",
    text: "personal area",
    url: "https://ae.exchange/lk#/login",
    selected: false,
  },*/
  {
    title: "AE Trade",
    text: "personal area",
    url: "https://ae.exchange/lktrade#/login",
    selected: false,
  },
  {
    title: "START TRADING",
    text: "how to access trading",
    url: "/starttrade",
    selected: false,
  },
  {
    title: "EXCHANGE DOCUMENTS",
    text: "rules, contract specifications",
    url: "/pdocs",
    selected: false,
  },
 /* {
    title: "TECHNOLOGIES AND INFRASTRUCTURE",
    text: "AE trading system, opportunities",
    url: "/eng/tech",
    selected: false,
  },*/
  {
    title: "GENERAL PROVISIONS ON TRADING",
    text: "trading instruments, commissions",
    url: "/onbidding",
    selected: false,
  },
 /* {
    title: "ABOUT OUR PROJECT",
    text: "goals and objectives, ideology",
    url: "/eng/info",
    selected: false,
  },*/
  {
    title: "Our Services",
    text: "products and services",
    url: "/products",
    selected: false
  },
  {
    title: "FAQ",
    text: "answers and questions",
    url: "/faq",
    selected: false,
  },
/*     {
    title: "FOR INVESTORS",
    text: "investing in AE",
    url: "/eng/investor",
    selected: false,
  },*/
 /* {
    title: "Token AE",
    text: "tokenomics",
    url: "/eng/token",
    selected: false,
  },*/
]
  const menuItemsRus: {
    title: string;
    text: string;
    url: string;
    selected: boolean;
  }[] = [

    {
      title: "Личный кабинет TRADE",
      text: "вход в личный кабинет клиента",
      url: "https://ae.exchange/lktrade#/login",
      selected: false,
    },
  /*  {
      title: "Game",
      text: "личный кабинет тестового контура",
      url: "https://ae.exchange/lk#/login",
      selected: false,
    },*/
    {
      title: "Начать торговать",
      text: "как получить доступ к торгам",
      url: "/starttrade",
      selected: false,
    },
    {
      title: "Документы Биржи",
      text: "правила, спецификации контрактов",
      url: "/pdocs",
      selected: false,
    },
   /* {
      title: "Технологии и инфраструктура",
      text: "торговая система АЕ, возможности",
      url: "/tech",
      selected: false,
    },*/
    {
      title: "Общие положения о торгах",
      text: "торговые инструменты, комиссии",
      url: "/onbidding",
      selected: false,
    },
   /* {
      title: "О нашем проекте",
      text: "цели и задачи, идеология",
      url: "/info",
      selected: false,
    },*/
    {
      title: "Наши сервисы ",
      text: "продукты и услуги",
      url: "/products",
      selected: false,
    },
    {
      title: "Частые вопросы",
      text: "FAQ по AE",
      url: "/faq",
      selected: false,
    },

    /*      {
          title: "Инвестору",
          text: "инвестировать в биржу AE",
          url: "/investor",
          selected: false,
        },*/
   /* {
      title: "Токен АЕ",
      text: "токеномика",
      url: "/token",
      selected: false,
    },*/
  ];
  let ls=storDef.getLang()
  let menuItems=menuItemsRus;
  if( ls===2){
    menuItems=menuItemsEng;
  }

  const menuRightItems = menuItems.map((item,i) => {
    return (
      <MenuRightItem 
        key={item.title+i}
        text={item.text}
        title={item.title}
        url={item.url}
        selected={(item.url===location.pathname)}
      ></MenuRightItem>
    );
  });
  return (
    <div id="sidemenu">
      <svg
        className="vline"
        width="2"
        height="1002"
        viewBox="0 0 2 1002"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.999956 -9.95398e-06L1.00004 1002"
          stroke="#E0E0E0"
          strokeDasharray="8 6"
        />
      </svg>
      <ul>
        <div>{menuRightItems}</div>
      </ul>
    </div>
  );
};
const MenuRightItem = ({
  title,
  text,
  url,
  selected,
}: {
  title: string;
  text: string;
  url: string;
  selected: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      className={`menuRightItem 
        ${selected === true ? "menuRightItemSelected" : ""}
        `}
    >
      <a href={url} style={{ textDecoration: "none" }}>
        {isHovering && (
          <svg
            style={{ top: "0", left: "13px" }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="11"
              fill="white"
              stroke="#BFBFBF"
              strokeWidth="2"
            />
            <rect
              x="6"
              y="6"
              width="12"
              height="12"
              rx="6"
              fill="white"
              stroke="#BFBFBF"
              strokeWidth="2"
            />
          </svg>
        )}
        {selected && (
          <svg
            style={{ top: "0", left: "13px" }}
            v-show="item.selected"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="11"
              fill="white"
              stroke="#e82862"
              strokeWidth="2"
            />
            <rect
              x="6"
              y="6"
              width="12"
              height="12"
              rx="6"
              fill="white"
              stroke="#e82862"
              strokeWidth="2"
            />
          </svg>
        )}
      {(!isHovering && !selected) && (  <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="1"
            width="8"
            height="8"
            rx="4"
            fill="white"
            stroke="#BFBFBF"
            strokeWidth="2"
          />
        </svg>
      )}
        <li>
          <div className="menuRightItemTitle">{title}</div>
          <div className="menuRightItemText">{text}</div>
        </li>
      </a>
    </div>
  );
};

export default MenuRight;
