import { makeAutoObservable } from "mobx";
import storeDef from "./Default";
export interface FAQItem {
  text: string;
  title: string;
  expand: boolean;
}
const faqs: FAQItem[] = [
  {
    title: "Как производится оплата комиссии на бирже АЕ?",
    text: "Оплата биржевой комиссии производится собственным токеном АЕ. Для проведения торговых операций требуется наличие на торговом счете клиента токена АЕ.",
    expand: true,
  },
  {
    title: "Как купить токен АЕ?",
    text: "Токен АЕ можно купить через биржевой стакан подав ордер на покупку, после совершения трейда в позициях по торговому счету появится токен АЕ.",
    expand: true,
  },
  {
    title: "В чем преимущества биржи АЕ?",
    text: "Торговые терминалы биржи АЕ обладают лучшим функционалом по моделированию опционных стратегий. Предоставляют возможности алгоритмической торговли на серверах биржи, включают набор готовых роботов. Торговое ядро биржи обладает высокой производительностью и стабильностью работы. Клиенты получают уникальный сервис торговли опционами и фьючерсами на криптовалюты при низком уровне комиссий. Для держателей токенов АЕ действует дисконт на комиссии.",
    expand: true,
  },
  {
    title: "Какие фьючерсные контракты торгуются на AE?",
    text: "На AE торгуются фьючерсы на BTC. ETH. MATIC. DOGE. Запускаются фьючерсные контракты на новые монеты.",

    expand: true,
  },
  {
    title: "Каковы размеры фьючерсных контрактов на AE?",
    text: "Размер одного фьючерса BTC составляет 0,001 BTC, на ETH составляет 0,01 ETH. Фьючерс MATIC на 100 монет MATIC. на 1000 монет фьючерс DOGE на Dogecoin",
    expand: true,
  },
  {
    title: "Дни экспираций фьючерсных контрактов?",
    text: "Фьючерсы квартальные, исполняются в последнюю пятницу месяца: март, июнь, сентябрь, декабрь",
    expand: true,
  },
  {
    title: "Какие серии опционов торгуются на AE?",
    text: "На AE торгуются маржируемые опционы на фьючерсы трех серий исполнения: квартальной, месячной и недельной. Опционы поставочные.",
    expand: true,
  },
  {
    title: "Как проходит экспирация опционов?",
    text: "Экспирации опционов соотв дат проходят по пятницам в 8:00 UTC. Экспирация автоматическая, подача поручений не требуется. Исполняются опционы в деньгах на 1 шаг цены",
    expand: true,
  },
  {
    title: "Есть ли на AE маркет-мейкеры?",
    text: "Да, на AE есть провайдеры ликвидности по всем торгуемым фьючерсным контрактам, а также по всем опционным сериям",

    expand: true,
  },
  {
    title: "Что такое Маржинальные требования?",
    text: "Маржинальные требования - это залоговые средства, наличие которых необходимо на счете для совершения сделок с фьючерсами и опционами. Маржинальные средства не списываются со счета, а только блокируются по активным ордерам и открытым позициям",
    expand: true,
  },
  {
    title: "Что такое Маржин-колл?",
    text: "Маржин-колл - требование о пополнении средств для устранения отрицательного значения свободного остатка средств по торговому счету клиента. Требование направляется на e-mail клиента",
    expand: true,
  },
  {
    title:
      "Как на АЕ осуществляются расчеты финансового результата по торговому счету?",
    text: "Расчеты финреза на АE осуществляются в режиме непрерывного клиринга, при котором финансовый результат по открытым позициям пересчитывается при каждом изменении цен инструментов",
    expand: true,
  },
  {
    title: "Как внести BTC на торговый счет AE?",
    text: "Путем перевода USDT, Bitcoin со своего кошелька на адрес кошелька прикрепленный к торговому счету АЕ, полученный в Личном кабинете.",
    expand: true,
  },
  {
    title: "Какой минимальный размер счета для торговли на AE?",
    text: "Минимальный порог размера счета на АЕ не установлен",

    expand: true,
  },
  {
    title: "Как вывести средства со счета AE?",
    text: "Путем подачи в Личном кабинете заявки на вывод средств, заполнив форму заявки с указанием адреса кошелька, на который будет осуществлен вывод средств",
    expand: true,
  },
  {
    title: "Когда происходит вывод c AE и есть ли комиссия за вывод?",
    text: "Вывод средств - 1 раз в день. Запрос, поданный до 14:00 UTC, обрабатывается до конца дня. По запросу после 14:00 UTC вывод средств производится на следующий день. AE не начисляет собственные комиссии за вывод средств",
    expand: true,
  },
  {
    title: "Не нашли ответ на вопрос?",
    text: "Пожалуйста, напишите нам свой вопрос: client@ae.exchange",
    expand: true,
  },
];
const faqsEng: FAQItem[] = [
  {
    title: "How is the commission paid on the AE exchange?",
    text: "Payment for the exchange commission is made with our own AE token. To conduct trading operations, it is required to have an AE token on the client's trading account.",
    expand: true,
  },
  {
    title: "How to buy an AE token?",
    text: "The AE token can be bought through the order book by submitting a buy order; after the trade is completed, the AE token will appear in the positions on the trading account.",
    expand: true,
  },
  {
    title: "What are the advantages of the AE?",
    text: "AE trading terminals have the best functionality for modeling option strategies. Providing opportunities for algorithmic trading on AE servers include a set of ready-made robots. The trading core of the exchange has high performance and stability. Clients receive a unique service for trading options and futures for cryptocurrencies at a low level of commissions. Holders of AE tokens have a discount on commission.",
    expand: true,
  },
  {
    title: "What futures contracts are traded on AE?",
    text: "BTCUSDT ETHUSDT MATICUSDT DOGEUSDT futures are traded on AE",

    expand: true,
  },
  {
    title: "What are the AE futures contract sizes?",
    text: "The size of one BTC futures is 0.001 BTC, on ETH it is 0.01 ETH. MATIC futures for 100 MATIC coins. for 1000 coins DOGE futures on Dogecoin",
    expand: true,
  },
  {
    title: "Expiry days for futures contracts?",
    text: "Quarterly futures, executed on the last Friday of the month: March, June, September, December",
    expand: true,
  },
  {
    title: "What series of options are traded on the AE?",
    text: "AE traded futures-style options on BTCUSDT ETHUSDT MATICUSDT DOGEUSDT  indices, three series: quarterly, monthly and weekly",

    expand: true,
  },
  {
    title: "How do options expire?",
    text: "Options expiration takes place on Friday of the current expiration date of the option series. Expiration is automatic, no orders are required. Options are exercised in-the-money for 1 price step",
    expand: true,
  },
  {
    title: "Are there market makers on AE?",
    text: "Yes, AE has liquidity providers for all traded futures contracts as well as all option series",

    expand: true,
  },
  {
    title: "What are Margin Requirements?",
    text: "Margin requirements are collateral, the availability of which is required on the account to make transactions with futures and options. Margin funds are not debited from the account, but only blocked for active orders and open positions. In the trading terminal are displayed in the GM field",
    expand: true,
  },
  {
    title: "What is Margin Call?",
    text: "Margin call is a requirement to replenish funds to eliminate the negative value of the free balance of the client's trading account. The request is sent to the client's e-mail",
    expand: true,
  },
  {
    title: "How is the financial result of a trading account calculated on AE?",
    text: "Settlements of financial result on AE are carried out in the continuous clearing mode, in which the financial result on open positions is recalculated when the prices of instruments change",
    expand: true,
  },
  {
    title: "How do I deposit into an AE trading account?",
    text: "By transferring USDT, BTC from your wallet to the wallet address attached to the AE trading account, received in the Personal Account.",
    expand: true,
  },
  {
    title: "What is the minimum account size to trade on AE?",
    text: "The minimum threshold for the size of the account on AE is not set",

    expand: true,
  },
  {
    title: "How to withdraw BTC from AE account?",
    text: "By submitting an application for withdrawal of funds in the Personal Account, filling out the application form indicating the address of the wallet to which the BTC will be withdrawn",
    expand: true,
  },
  {
    title: "When is BTC withdrawn from AE and is there a withdrawal fee?",
    text: "Withdrawing funds in BTC - once a day. A request submitted before 14:00 UTC is processed by the end of the day. Upon request after 14:00 UTC, withdrawals are made the next day. AE does not charge its own withdrawal fees",
    expand: true,
  },
  {
    title: "Didn't find the answer to the question?",
    text: "Please write your question to us: client@ae.exchange",
    expand: true,
  },
];

class FAQ {
  headerRus="Помощь"
  titleRus="Частые вопросы"
  linkRus="Все частые вопросы"
  headerEng="FAQ"
  titleImgRus="Частые вопросы по  <br />торговле на бирже AE"
  titleEng="Answers on questions"
  linkEng="FAQs"
  titleImgEng="Frequently asked questions and answers  <br />about trading on the AE exchange"
  constructor() {
    makeAutoObservable(this);
  }

  getLastFAQ = (num: number) => {
    if (storeDef.getLang() === 2) {
      return faqsEng.slice(0, num);
    }
    return faqs.slice(0, num);
  };
  getAllFAQ = () => {
    if (storeDef.getLang() === 2) {
      return faqsEng;
    }
    return faqs;
  };
  getHeader = () => {
    if (storeDef.getLang() === 2) {
      return this.headerEng;
    }
    return this.headerRus;
  };
  getTitle = () => {
    if (storeDef.getLang() === 2) {
      return this.titleEng;
    }
    return this.titleRus;
  };
  getTitleImg = () => {
    if (storeDef.getLang() === 2) {
      return this.titleImgEng;
    }
    return this.titleImgRus;
  };
  getLink = () => {
    if (storeDef.getLang() === 2) {
      return this.linkEng;
    }
    return this.linkRus;
  };
}

export default new FAQ();
