import React, { useState, useEffect} from "react";
import axios from "axios";
//import * as Echarts from 'echarts/core';
import InstrumentsStore from "../../Store/Instruments";
import { observer } from "mobx-react-lite";
import ReactECharts from "echarts-for-react/";

//import * as lodash from "lodash";
//import axios from 'axios'
import //LineChart,
// BarChart,
// PieChart,
// ScatterChart,
// RadarChart,
// MapChart,
// TreeChart,
// TreemapChart,
// GraphChart,
// GaugeChart,
// FunnelChart,
// ParallelChart,
// SankeyChart,
// BoxplotChart,
// CandlestickChart,
// EffectScatterChart,
// LinesChart,
// HeatmapChart,
// PictorialBarChart,
// ThemeRiverChart,
// SunburstChart,
// CustomChart,
"echarts/charts";
import // GridSimpleComponent,
//GridComponent,
// PolarComponent,
// RadarComponent,
// GeoComponent,
// SingleAxisComponent,
// ParallelComponent,
// CalendarComponent,
// GraphicComponent,
// ToolboxComponent,
// TooltipComponent,
// AxisPointerComponent,
// BrushComponent,
// TitleComponent,
// TimelineComponent,
// MarkPointComponent,
// MarkLineComponent,
// MarkAreaComponent,
// LegendComponent,
// LegendScrollComponent,
// LegendPlainComponent,
// DataZoomComponent,
// DataZoomInsideComponent,
// DataZoomSliderComponent,
// VisualMapComponent,
// VisualMapContinuousComponent,
// VisualMapPiecewiseComponent,
// AriaComponent,
// TransformComponent,
// DatasetComponent,
"echarts/components";
// import { UniversalTransition } from 'echarts/features';

// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import //  CanvasRenderer,
// SVGRenderer,
"echarts/renderers";

const Chart =observer( ({ notTransparent = false }: { notTransparent: boolean }) => {
  //   Echarts.use( [ GridComponent, LineChart, CanvasRenderer, UniversalTransition])

  /* interface BarsData {
    categoryData: number[];
    values: number[][];
    volumes: number[][];
  }*/
  const [loaded, setLoaded] = useState(false);
  const [option, setOption] = useState({});
  useEffect(() => {
    setLoaded(false)
    LoadData("")
},[InstrumentsStore.SelectedFuture])

  /*const [data, setData] = useState<BarsData>({
    categoryData: [],
    values: [],
    volumes: [],
  });*/
  //let data:{categoryData:number[],values:number[][],volumes:number[][]}
  const upColor = "#0EBF95";
  const downColor = "#F32F52";
  let last = 0;
  let lastColor = upColor;
  const getStringDate = (dt: number) => {
    return new Date(dt).toISOString().slice(0, 16).replace("T", " ");
  };
  const splitData = (rawData: number[][]) => {
    /*["2004-01-02",10452.74,10409.85,10367.41,10554.96,168890000]*/
    /*[24187.0,24187.0,24142.0,24144.0,7.0,1660143000]*/
    last = rawData[0][3];
    let categoryData: string[] = [];

    let values: number[][] = [];
    let volumes: number[][] = [];
    for (let i = 0; i < rawData.length; i++) {
      let dt: number = +rawData[i].splice(5, 1)[0];
      categoryData.unshift(getStringDate(dt * 1000));
      let v = [rawData[i][0], rawData[i][3], rawData[i][2], rawData[i][1]];
      values.unshift(v);
      volumes.unshift([
        i,
        rawData[i][4],
        rawData[i][0] < rawData[i][3] ? 1 : -1,
      ]);
    }
    if (volumes[rawData.length - 1][2] === -1) {
      lastColor = downColor;
    }
    return {
      categoryData: categoryData,
      values: values,
      volumes: volumes,
    };
  };

  const calculateMA = (dayCount: number, data: { values: number[][] }) => {
    var result: any = [];
    for (var i = 0, len = data.values.length; i < len; i++) {
      if (i < dayCount) {
        result.push("-");
        continue;
      }
      var sum = 0;
      for (var j = 0; j < dayCount; j++) {
        sum += data.values[i - j][1];
      }
      result.push(+(sum / dayCount).toFixed(3));
    }
    return result;
  };
  const LoadData = ( size:string) => {
    if (loaded === true) {
      return;
    }
    axios
      .post("https://ae.exchange/lktrade/users/instrument/history", {
        Ticker: InstrumentsStore.SelectedFuture,
        Size: size,
      })
      .then((response) => {
        const res = JSON.parse(response.data.Bars);
        // console.log(res.data);
        const data = splitData(res.data);
        // setData(dt);
        const optionNew = {
          darkMode: true,
          animation: false,
          legend: {
            bottom: 10,
            left: "center",
            data: [response.data.Ticker, "MA20"],
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
            borderWidth: 1,
            borderColor: "#74ABE1",
            backgroundColor: "#212332",
            padding: 10,
            textStyle: {
              color: "#fff",
            },
            position: function (/*pos, params, el, elRect, size*/) {
              const obj = {
                top: 10,
              };
              //  obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
              return obj;
            },
            // extraCssText: 'width: 170px'
          },
          axisPointer: {
            link: [
              {
                xAxisIndex: "all",
              },
            ],
            label: {
              backgroundColor: "#777",
            },
          },
          toolbox: {
            feature: {
              dataZoom: {
                yAxisIndex: false,
              },
              brush: {
                type: ["lineX", "clear"],
              },
            },
          },
          brush: {
            xAxisIndex: "all",
            brushLink: "all",
            outOfBrush: {
              colorAlpha: 0.1,
            },
          },
          visualMap: {
            show: false,
            seriesIndex: 5,
            dimension: 2,
            pieces: [
              {
                value: 1,
                color: downColor,
              },
              {
                value: -1,
                color: upColor,
              },
            ],
          },
          grid: [
            {
              left: "10%",
              right: "8%",
              height: "50%",
            },
            {
              left: "10%",
              right: "8%",
              top: "63%",
              height: "16%",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: data.categoryData,
              boundaryGap: false,
              axisLine: { onZero: false },
              splitLine: { show: false },
              min: "dataMin",
              max: "dataMax",
              axisPointer: {
                z: 100,
              },
            },
            {
              type: "category",
              gridIndex: 1,
              data: data.categoryData,
              boundaryGap: false,
              axisLine: { onZero: false },
              axisTick: { show: false },
              splitLine: { show: false },
              axisLabel: { show: false },
              min: "dataMin",
              max: "dataMax",
            },
          ],
          yAxis: [
            {
              scale: true,
              splitArea: {
                show: true,
              },
              position: "right",
            },
            {
              scale: true,
              gridIndex: 1,
              splitNumber: 2,
              axisLabel: { show: false },
              axisLine: { show: false },
              axisTick: { show: false },
              splitLine: { show: false },
            },
          ],
          dataZoom: [
            {
              type: "inside",
              xAxisIndex: [0, 1],
              start: 75,
              end: 100,
            },
            {
              show: true,
              xAxisIndex: [0, 1],
              type: "slider",
              top: "85%",
              start: 0,
              end: 100,
            },
          ],
          series: [
            {
              name: response.data.Ticker,
              type: "candlestick",
              data: data.values,
              itemStyle: {
                color: upColor,
                color0: downColor,
                borderColor: undefined,
                borderColor0: undefined,
              },
              markLine: {
                data: [
                  {
                    yAxis: last,
                  },
                ],
                label: {
                  backgroundColor: lastColor,
                  borderRadius: 4,
                  fontSize: 14,
                  fontWeight: "bold",
                  padding: [5, 5, 5, 5],
                },
                lineStyle: {
                  color: lastColor,
                },
                animation: true,
              },
              tooltip: {
                formatter: function (param: any) {
                  param = param[0];
                  return [
                    "Date: " + param.name + '<hr size=1 style="margin: 3px 0">',
                    "Open: " + param.data[0] + "<br/>",
                    "Close: " + param.data[1] + "<br/>",
                    "Lowest: " + param.data[2] + "<br/>",
                    "Highest: " + param.data[3] + "<br/>",
                  ].join("");
                },
              },
            },
            /*       {
              name: "MA5",
              type: "line",
              data: calculateMA(5, data),
              smooth: true,
              lineStyle: {
                opacity: 0.5,
              },
            },
            {
              name: "MA10",
              type: "line",
              data: calculateMA(10, data),
              smooth: true,
              lineStyle: {
                opacity: 0.5,
              },
            },*/
            {
              name: "MA20",
              type: "line",
              data: calculateMA(20, data),
              smooth: true,
              lineStyle: {
                color: "#979BB4",
                opacity: 0.8,
              },
            },
            /*      {
              name: "MA30",
              type: "line",
              data: calculateMA(30, data),
              smooth: true,
              lineStyle: {
                opacity: 0.5,
              },
            },*/
            {
              name: "Volume",
              type: "bar",
              itemStyle: {
                color: "#979BB4",
              },
              xAxisIndex: 1,
              yAxisIndex: 1,
              data: data.volumes,
            },
          ],
        };
        setOption(optionNew);
        setLoaded(true);
      });
  };
  LoadData("");
  //return <ReactECharts option={option}  />;
  return (
    <ReactECharts
      option={option}
      theme="dark"
      style={{
        height: "100%",
        transition: "visibility 0s, opacity 0.5s linear",
        ...(notTransparent ? { opacity: "1" } : { opacity: "0.3" }),
      }}
    />
  );
});
export default Chart;
