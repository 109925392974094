import React from "react";
import "./DocsTable.scss";
interface DocInfo {
  type: string;
  name: string;
  url: string;
}
const DocsTable = ({ title, list }: { title: string; list: DocInfo[] }) => {
  const item = list.map((item) => {
    return (
      <a key={item.name} className="doctable_table_link" href={item.url}>
        <li className="doctable_table_li">
          <div className={item.type}></div>
          <div className="doctable_table_name" dangerouslySetInnerHTML={{ __html:item.name}}></div>
        </li>
      </a>
    );
  });

  return (
    <div className="doctable_body">
      <div className="doctable_title">{title}</div>
      <div className="doctable_table">
        <ul className="doctable_table_ul">{item}</ul>
      </div>
      <div style={{ marginTop: "-55px", marginLeft: "595px" }}>
        <svg
          width="107"
          height="107"
          viewBox="0 0 107 107"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="1.5" cy="79.5" r="1.5" fill="#E0E0E0" />
          <circle cx="1.5" cy="105.5" r="1.5" fill="#E0E0E0" />
          <circle cx="27.5" cy="79.5" r="1.5" fill="#E0E0E0" />
          <circle cx="27.5" cy="105.5" r="1.5" fill="#E0E0E0" />
          <circle cx="53.5" cy="79.5" r="1.5" fill="#E0E0E0" />
          <circle cx="53.5" cy="105.5" r="1.5" fill="#E0E0E0" />
          <circle cx="79.5" cy="1.5" r="1.5" fill="#E0E0E0" />
          <circle cx="79.5" cy="27.5" r="1.5" fill="#E0E0E0" />
          <circle cx="79.5" cy="53.5" r="1.5" fill="#E0E0E0" />
          <circle cx="79.5" cy="79.5" r="1.5" fill="#E0E0E0" />
          <circle cx="79.5" cy="105.5" r="1.5" fill="#E0E0E0" />
          <circle cx="105.5" cy="1.5" r="1.5" fill="#E0E0E0" />
          <circle cx="105.5" cy="27.5" r="1.5" fill="#E0E0E0" />
          <circle cx="105.5" cy="53.5" r="1.5" fill="#E0E0E0" />
          <circle cx="105.5" cy="79.5" r="1.5" fill="#E0E0E0" />
          <circle cx="105.5" cy="105.5" r="1.5" fill="#E0E0E0" />
        </svg>
      </div>
    </div>
  );
};

export default DocsTable;
