import React from "react";
import "./ServicesAndProducts.scss"
import Header from "../../Components/Header/Header";
import ImageChartMenu from "../../Components/ImageChartMenu/ImageChartMenu";
import Footer from "../../Components/rus/Footer/Footer";
import storDef from "../../Store/Default";
import { observer } from "mobx-react-lite";

const Products = observer(() => {
  const infosRus = {
    titleImg: "Наши сервисы",
    textImg: "продукты и услуги",
    text: [
      {
        title: "Торговые терминалы",
        text:
          "<b>Десктоп терминал АЕ</b> под ОС Windows это завоевавший большую популярность у опционных трейдеров терминал Option-lab.<br/>" +
          "<b>Веб терминал АЕ</b> совмещает отличные аналитические возможности и торговых роботов в окне браузера на любом устройстве. ",
      },
      {
        title: "Стратегии",
        text:
          "Популярные готовые опционные стратегии для инвесторов, спекулянтов и майнеров. Для получения результата стратегии достаточно просто оформить подписку. Цены токенов стратегий ежедневно обновляются." ,
      },
      {
        title: "Фонды",
        text:
          "Линейка фондов от опытных опционных и алго трейдеров. Возможность получить результат торговой стратегии управляющего фонда на опционах и фьючерсах на криптовалюты." ,
      },
      {
        title: "Обучение",
        text:
          "Лучшие онлайн курсы обучения торговли опционами по уровням: Базовый, Эксперт и Мастер. Видео уроки, контрольные тесты и практические задания в торговых терминалах биржи АЕ обеспечат максимальную эффективность изучения торговли опционами." ,
      },
   /*   {
        title: "Маркет плейс АЕ МАГАЗИН",
        text:
          "<b>СТРАТЕГИИ </b>линейка популярных готовых опционных стратегий для получения финансового результата которых достаточно просто оформить подписку. Цена токена Стратегии ежедневно обновляется.<br/>" +
          "<b>ОБУЧЕНИЕ</b> лучшие онлайн курсы обучения торговли опционами по уровням: Базовый, Эксперт и Мастер. Видео уроки, контрольные тесты и практические задания в торговых терминалах биржи АЕ обеспечат максимальную эффективность изучения опционной торговли. ",
      },*/
      {
        title: "Пополнение торгового счета и вывод средств",
        text: "В качестве средств обеспечения торговли фьючерсами и опционами на бирже АЕ используются USDT и BTC. Автоматическое зачисление средств при пополнении и ручная проверка при выводе средств.",
      },

      {
        title: "Конвертация",
        text: "Простой и удобный сервис обмена криптовалют по индикативному курсу. ",
      },

      {
        title: "Токен АЕ",
        text: "Токен АЕ несет в себе ценность экосистемы биржи АЕ для сообщества. Используется для оплаты биржевой комиссии, сервисов и продуктов АЕ. Токен дает право голоса по вопросам развития АЕ. Держатель 1000 и более токенов получает: членство в Совете биржи АЕ, дисконт 50% комиссий биржи АЕ. Смарт контракт блокчейна Tron (TRC 20). ",
      },
    /*  {
        title: "Тестирование",
        text: "Пройдите тесты на знания базовых понятий и параметров опционов, а также образовательный тест на знание правил и торговых инструментов биржи АЕ.",
      },*/
      {
        title: "Клуб",
        text:
          "Клуб <<Опцион LAFT>> объединяет единомышленников торговли опционами на бирже с образовательными, финансовыми и инвестиционными целями. <br/>"+
          "<a href='https://ae.exchange/club' style='color:#E82862'>  Подробнее" +
          '<div class="soc_p_link">  <svg  style={{ paddingLeft: "15px", marginBottom: "-2px" }} width="25"  height="14"  viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M2 1L5 7L2 13H5L9 7L5 1H2Z" stroke="#E82862" strokeWidth="1.5" /></svg></div></a>'
      },
      {
        title: "Вход в Личный кабинет игрового контура АЕ Game. ",
        text: "<a href='https://ae.exchange/lk#/login' style='color:#E82862'> Ознакомление с десктоп терминалом АЕ и тестирование торговых стратегий."
        +'<div class="soc_p_link">  <svg  style={{ paddingLeft: "15px", marginBottom: "-2px" }} width="25"  height="14"  viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M2 1L5 7L2 13H5L9 7L5 1H2Z" stroke="#E82862" strokeWidth="1.5" /></svg></div></a>'
      },
    ],
  };
  const infosEng = {
    titleImg: "Our Services",
    textImg: "products and services",
    text: [
      {
        title: "Trading terminals",
         text:
           "<b>AE desktop terminal</b> under Windows OS is the Option-lab terminal, which has gained great popularity among option traders.<br/>" +
           "<b>AE Web Terminal</b> combines excellent analytical capabilities and trading robots in a browser window on any device. ",
      },
      {
        title: "Strategies",
        text:
          "Popular ready-made option strategies for investors, speculators and miners. To get the result of the strategy, just subscribe. Prices of strategy tokens are updated daily." ,
      },
      {
        title: "Funds",
        text:
          "A line of funds from experienced option and algo traders. The opportunity to get the result of the fund manager's trading strategy on options and futures for crypto-currencies." ,
      },
      {
        title: "Training",
        text:
          "The best online options trading courses by levels: Basic, Expert and Master. Video lessons, control tests and practical tasks in the trading terminals of the AE exchange will ensure the maximum effectiveness of learning options trading." ,
      },
  /*    {
        title: "Market Place AE STORE",
        text:
          "<b>STRATEGIES</b> a line of popular ready-made option strategies to get the financial result of which you just need to subscribe. The price of the Strategy token is updated daily.<br/>" +
          "<b>EDUCATION</b> the best online options trading courses by levels: Basic, Expert and Master. Video lessons, control tests and practical tasks in the trading terminals of the AE exchange will ensure the maximum effectiveness of learning options trading. ",
      },*/
      {
        title: "Trading Account Deposit and Withdrawal",
        text: "USDT and BITCOIN are used as a means of securing futures and options trading on the AE exchange. Automatic crediting of funds when replenishing and manual verification when withdrawing funds.",
      },

      {
        title: "Convert",
        text: "A simple and convenient cryptocurrency exchange service at an indicative rate. ",
      },

      {
        title: "AE Token",
        text: "AE token carries the value of the AE exchange ecosystem for the community. It is used to pay the exchange commission, services and products of AE. The token gives the right to vote on the development of AE. The holder of 1000 or more tokens receives: membership in the Council of the AE exchange, discount 50 AE Exchange Fee % Tron Blockchain Smart Contract (TRC 20).",
      },
   /*   {
        title: "Test",
        text: "Pass tests on knowledge of basic concepts and parameters of options, as well as an educational test on knowledge of the rules and trading tools of the AE exchange.",
      },*/
      {
        title: "Club",
        text:
          "The <<Option LAFT>> club brings together like-minded people who trade options on the stock exchange for educational, financial and investment purposes. <br/>"+
          "<a href='https://ae.exchange/club' style='color:#E82862'>  More" +
          '<div class="soc_p_link">  <svg  style={{ paddingLeft: "15px", marginBottom: "-2px" }} width="25"  height="14"  viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M2 1L5 7L2 13H5L9 7L5 1H2Z" stroke="#E82862" strokeWidth="1.5" /></svg></div></a>'
      },
      {
        title: "Entering the Personal Account of the AE Game gaming circuit. ",
        text: "<a href='https://ae.exchange/lk#/login' style='color:#E82862'> Introduction to the AE desktop terminal and testing trading strategies."
        +'<div class="soc_p_link"> <svg style={{ paddingLeft: "15px", marginBottom: "-2px" }} width="25" height="14" viewBox="0 0 10 14" fill= "none" xmlns="http://www.w3.org/2000/svg" > <path d="M2 1L5 7L2 13H5L9 7L5 1H2Z" stroke="#E82862" strokeWidth="1.5" /></svg> </div></a>'
      },
    ],
  };
  let info = infosRus;
  let ls = storDef.getLang();

  if (ls === 2) {
    info = infosEng;
  }
  return (
    <div className="body">
      <Header />

      <ImageChartMenu titleImg={info.titleImg} textImg={info.textImg} />

      <div className="page_content">
        <div className="page_content_left">
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[0].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[0].text }}
          ></div>
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[4].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[4].text }}
          ></div>
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[5].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[5].text }}
          ></div>
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[6].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[6].text }}
          ></div>
                    <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[8].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[8].text }}
          ></div>
        </div>
        <div className="page_content_vline"></div>
        <div className="page_content_right">
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[1].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[1].text }}
          ></div>
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[2].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[2].text }}
          ></div>
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[3].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[3].text }}
          ></div>
          <div
            className="onbidding_h_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[7].title }}
          ></div>
          <div
            className="onbidding_text_content"
            style={{ marginTop: "30px" }}
            dangerouslySetInnerHTML={{ __html: info.text[7].text }}
          ></div>
        </div>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
});

export default Products;
