import { makeAutoObservable } from "mobx";
interface NewsItem {
  date: string;
  title: string;
  content: string;
}
const news: NewsItem[] = [
  {
    date: "2024-09-15",
    title: "Запуск мобильного приложения AE для всех клиентов",
    content:
      "Приложение АЕ это торговый терминал крипто деривативами в твоем Android смартфоне:<br><br>" +
      "- Доски опционов и конструктор опционных стратегий с широкими возможностями моделирования.<br><br>" +
      "- Торговые роботы (Дельта хеджер, сеточник Stairs и другие) и ручная торговля в стаканах котировок фьючерсами и опционами.<br><br>" +
      "- Графики опционных комбинаций на аккаунте АЕ и ценовые графики с ордерами и трейдами." +
      "Создавай опционные стратегии и торгуй опционами в своем телефоне везде!",
  },
  {
    date: "2024-07-30",
    title: "Стартовали торги опционами на фьючерc TON",
    content:
      "Размер контракта 1 фьючерса и опциона TON состовляет 10 Toncoin.<br><br>" +
      "Марижнальные требования 30%.<br><br>" +
      "Комиссия: Maker 0.0005 AE, Taker 0.001 AE.<br><br>",
  },
  {
    date: "2024-07-22",
    title:
      "Сегодня Биржа АЕ ввела новые гибкие тарифы комиссий за сделки Maker/Taker по всем фьючерсам и опционам!",
    content:
      "Для сделок Taker комиссия остается без изменений.<br><br>" +
      "50% скидка комиссии для сделок MAKER.<br><br>" +
      "Смотрите обновленные <a href='https://ae.exchange/docs/rus/fees_ru.pdf'  target='_blank'>Комиссии</a> биржи АЕ.<br><br>",
  },
  {
    date: "2024-06-04",
    title: "Открыта подписка на готовую опционную стратегию SPUTISOL",
    content:
      "Стратегия направлена на долгосрочное получение прибыли за счет постоянной продажи премии опционов пут в деньгах и положительной дельты по SOLANA фьючерсу.<br><br>" +
      "- Опционы: месячной серии на фьючерс  SOLANA<br><br>" +
      "- Страйки: в деньгах, дельта 0,65-0,75<br><br>" +
      "- Лонгирование: в следующую месячную серию за неделю до экспирации текущей серии опционов<br><br>" +
      "- Риск стратегии: значительное падение цены фьючерса SOL.<br><br>" +
      "Минимальный объем подписки: 100 USDT.<br><br>",
  },
  {
    date: "2024-05-25",
    title: "Биржевой конкурс:  'Лучшая опционная стратегия'.",
    content:
      "Покажите свои навыки торговли опционами на AE.<br><br>" +
      "Правила участия:<br><br>" +
      "Выложите в нашем чате скриншот опционной стратегии, добавив краткое описание с ответом на вопрос:<br><br>" +
      "Почему вы считаете, что ваша стратегия лучшая на рынке?<br><br>" +
      "Победитель будет выбран путем голосования в телеграм канале АЕ.<br><br>" +
      "Призы: Победитель получает 5 токенов АЕp на свой счет.<br><br>" +
      "Принимаем участие <a href='https://t.me/optionlab' >здесь</a><br><br>",
  },
  {
    date: "2024-05-23",
    title:
      "На бирже опционов и фьючерсов на криптовалюты АЕ открыта подписка на фонд ReverWheel (Реверсивное Колесо).",
    content:
      "Фонд состоит из портфеля всех крипто активов торгуемых на бирже АЕ, вложения фонда между активами распределены в равных долях.<br><br>" +
      "Фонд предусматривает нахождение средств инвесторов в позиции по господствующему тренду с финансовым плечом кратным двум с одновременным сбором распада временной стоимости опционов.<br><br>" +
      "Подписаться на фонд можно в Личном кабинете на сайте биржи.<br><br>",
  },
  {
    date: "2024-04-29",
    title:
      "На бирже крипто деривативов АЕ стартовали торги фьючерсом SOLUSDT (SOLM24) и опционами",
    content:
      "Теперь на бирже ae.exchange торгуются опционы и фьючерсы <br><br>" +
      "- BITCOIN<br><br>" +
      "- Ethereum<br><br>" +
      "- Matic (Polygon) <br><br>" +
      "- DogeCoin <br><br>" +
      "- SOLANA<br><br>",
  },
  {
    date: "2024-01-05",
    title: "Открыта подписка на новый фонд BTC Wheel ATM (USDT)",
    content:
      "Фонд использует консервативную инвестиционную стратегия на Bitcoin - продажи PUT ATM опционов коротких сроков.<br><br>" +
      "При подписке на стратегию, а также при отписке комиссия не взимается.<br><br>" +
      "Подробнее с новым фондом BTC Wheel ATM можно ознакомится в Личном кабинете АЕ.<br><br>",
  },
  {
    date: "2023-07-12",
    title: "Открыта подписка на новую готовую стратегию SPUTICRYPTO",
    content:
      "Консервативная инвестиционная стратегия на корзину криптовалют использует плюсы продажи ITM PUT опционов и получении Cash Flow от распада их временной премии.<br><br>" +
      "Опционы: продажи опционов PUT месячных серий на фьючерсы в долях BTC (40%) ETH (30%) DOGE (20%) MATIC (10%).<br><br>" +
      "При подписке на стратегию, а также при отписке комиссия не взимается.<br><br>" +
      "Подробнее с новой готовой стратегией можно ознакомится в Личном кабинете.<br><br>",
  },
  {
    date: "2023-05-06",
    title: "Проведено большое обновление веб терминала АЕ",
    content:
      "Новая графическая библиотека. Stock Chart c поддержкой добавления графических элементов, индикаторов теханализа, изменения типа отображения (Candlestick, HLC, Line, etc...).<br><br>" +
      "Новые графики PnL опционных стратегий. Добавлено отображение тикеров и количества, размера требуемых маржинальных требований GM. Сделаны исправления.<br><br>" +
      "Добавлен новый функционал в систему сообщений: подержка отправки вложенных файлов и возможность редактирования своих сообщений.<br><br>" +
      "Новый торговой робот Trend. Зарабатывает на направленных ценовых движениях. Считает прибыль и убытки по своим сделкам. Закрывает позицию при достижении заданной прибыли или макс риска.<br><br>" +
      "Устранены выявленные недоработки.<br><br>",
  },
  {
    date: "2023-03-27",
    title: "Возобновлена работа клуба Опцион LAFT",
    content:
      "Далеко не все опционные трейдеры готовы делиться идеями опционных стратегий и торговли волатильностью в открытом доступе. Закрытое сообщество решает проблему инфо шума и позволяет сфокусироваться на мнениях экспертов.<br><br>" +
      "Клуб Опцион LAFT это место общения и обмена опытом, стратегиями между опционными трейдерами. Библиотека знаний по опционной торговле.<br><br>" +
      "Торговые и обучающие вебинары позволяют построить свои прибыльные стратегии и получить ответы на любые вопросы опционной торговли. <br><br>" +
      "Заявку на подписку в клуб Опцион LAFT можно отправить в Личном кабинете АЕ. Вопросы направляйте client@ae.exchange <br><br>",
  },
  {
    date: "2023-03-03",
    title: "Новый сервис в экосистеме АЕ: Фонды АЕ",
    content:
      "Создаем уникальную линейка фондов с долевым участием, которые торгуют опционные и алго стратегии на криптовалюты. <br><br>" +
      "В Личном кабинете АЕ открыта подписка на первый фонд.<br><br>" +
      "Фонды АЕ дают возможность: <br><br>" +
      "- Трейдерам масштабировать свои торговые стратегии на большем капитале и больше зарабатывать.<br><br>" +
      "- Клиентам получить дополнительный доход от участия в торговой стратегии заинтересовавшего трейдера, диверсифицировать свой торговый портфель.<br><br>" +
      "Фонды АЕ это: <br><br>" +
      "- ежедневная оценка паев фонда <br><br>" +
      "- контроль за торговыми операция <br><br>" +
      "- саппорт управляющих фонда <br><br>" +
      "- отсутствие доп комиссий  <br><br>" +
      "C кратким описанием фонда и условиями можно ознакомится в Личном кабинете АЕ.<br><br>",
  },
  {
    date: "2023-02-23",
    title: "Готовые стратегии АЕ без комиссии.",
    content:
      "- Отменены комиссии на подписку на все готовые стратегии АЕ.<br><br>",
  },
  {
    date: "2023-01-31",
    title:
      "Биржа АЕ начала предоставлять доступ клиентам с своему биржевому шлюзу API AE.",
    content:
      "- Доступ к API AE предоставляется для подключения внешних торговых систем и собственного софта к торговой системе биржи АЕ. В разделе сайта АЕ Документы Биржи опубликована Спецификация протокола подключения Client API Websocket и Пример для подключения к тестовому серверу AE Game<br><br>",
  },
  {
    date: "2022-12-24",
    title:
      "Новый функционал веб терминала АЕ дает возможность создать своего торгового робота.",
    content:
      "- В новом Custom bot реализована возможность создать своего торгового робота. <br><br>Алгоритм робота описывается скриптами, имеющими синтаксис языков Java/JavaScript. <br><br>Смотрите короткое видео <a href='https://youtu.be/tLtrLSQfm98'>на Youtube канале </a><br><br>",
  },
  {
    date: "2022-11-16",
    title: "Стартовали торги опционами и фьючерсом на Dogecoin (DOGE)",
    content:
      "- Квартальные фьючерсы, размер одного контракта 1000 монет DOGE. Доступен декабрьский фьючерс DOGEZ22<br><br>" +
      "- Маржинальные требования фьючерса DOGE GM равно 30%<br><br>" +
      "- Опционы месячных и квартальных серий. Доступна серия опционов 30.12.2022<br><br>" +
      "- ММ в опционах 2+7 страйков. Шаг страйка 10<br><br>" +
      "Подробную информацию по новым контрактам смотрите в Документы биржи<br><br>",
  },
  {
    date: "2022-10-25",
    title:
      "Стартовали торги опционами и фьючерсом на коин  MATIC проекта POLIGON",
    content:
      "- Квартальные фьючерсы, размер одного контракта 100 монет MATIC. Доступен декабрьский фьючерс MATICZ22<br><br>" +
      "- Маржинальные требования фьючерса MATIC GM равно 25%<br><br>" +
      "- Опционы месячных и квартальных серий. Текущие доступные серии опционов 25.11 и 30.12.2022<br><br>" +
      "-  ММ в опционах 2+7 страйков<br><br>",
  },
  {
    date: "2022-08-30",
    title: "Обновление веб терминала АЕ",
    content:
      "Провели большое обновление веб терминала АЕ, основные доработки и исправления:<br><br>" +
      "- Сделан расчет маржинальных требований GM в доске опционов в режиме Offline для заданных значениях цены фьючерса и IV опционов. Такой расчет необходим при моделировании опционных стратегий.<br><br>" +
      "- Оптимизировано качество соединения с сервером.<br><br>" +
      "- Сделано сохранение всех открытых серий опционов в доске опционов.<br><br>" +
      "- Исправлена ошибка отображения количества контрактов своих ордеров в стакане котировок и Orders.<br><br>" +
      "- Исправлены ошибки в логике бота option-MM.<br><br>" +
      "- Исключена задержка в расчете цен и греков после выбора новой серии в доске опционов.<br><br>" +
      "- Исправлена ошибка дубля ордеров бота Stairs при ежедневном рестарте веб сервера.<br><br>" +
      "- В окно Orders добавлена кнопка Cancel All для отмены всех активных ордеров по аккаунту.<br><br>",
  },
  {
    date: "2022-08-23",
    title:
      "Новая готовая Стратегия для майнеров: Страховка от падения цены на биткоин",
    content:
      "Новая готовая Стратегия АЕ для майнеров: HedgeBTC - стратегия хеджирования от сильного падения биткоин путем покупки put опционов месячной серии дает в пределе экспозицию х20 плечо шорт фьючерса BTC.<br> Доступна к подписке в Личном кабинете AE Trade.<br><br>",
  },
  {
    date: "2022-08-10",
    title: "Увеличено вознаграждение партнеров до 40%",
    content:
      "По пожеланиям участников нашего сообщества увеличена величина Партнерского вознаграждения биржи АЕ до 40%.<br><br>",
  },
  {
    date: "2022-08-09",
    title: "BuyIVBTC новинка Стратегий АЕ",
    content:
      "Buy IV BTC стратегия покупки IV опционов на биткоин фьючерс, приносит прибыль при росте волатильности  опционов IV и роста реализуемой волатильности RV фьючерса Bitcoin.<br><br>",
  },
  {
    date: "2022-08-02",
    title: "Новая Стратегия АЕ: продажа волатильности SIVBTC",
    content:
      "В АЕ МАГАЗИН доступна подписка на Стратегия  SIVBTC:  <br><br>" +
      "Sell IV BTC стратегия продажи IV опционов на биткоин фьючерс, приносит прибыль при снижении волатильности опционов IV, а также от временного распада проданных опционов. <br><br>",
  },
  {
    date: "2022-07-22",
    title: "SPUTIETH новый продукт линейки Стратегии",
    content:
      "Запущен новый продукт в линейке Стратегии SPUTIETH - продажа опционов пут в деньгах на фьючерс эфир ETH.<br><br>" +
      "Начальная цена 1 USDT. Доступен для подписки в разделе АЕ МАГАЗИН Личного кабинета клиента биржи АЕ.",
  },
  {
    date: "2022-07-08",
    title: "Запуск нового класса продуктов - Стратегии",
    content:
      "Биржа АЕ запустила новый класс продуктов Стратегии, это линейка популярных готовых опционных стратегий для получения финансового результата по которым достаточно просто оформить подписку.  <br><br>" +
      "Первым новым продуктом линейки стал SPUTIBTC. <br><br>" +
      "SPUTIBTC - опционная стратегия проданный пут в деньгах ( Sold Put ITM BTC ) на биткоин фьючерс. Стратегия постоянно пролонгируется на следующую опционную серию. Цена изменяется ежедневно и доступна как в Личном кабинете, так и в торговых терминалах. SPUTIBTC доступен для подписки в разделе АЕ МАГАЗИН Личного кабинета клиента биржи АЕ.",
  },
  {
    date: "2022-06-30",
    title: "Обновление торговой системы AE Trade",
    content:
      "В 12.00 мск будет производиться обновление торговой системы AE Trade, торговая система будет недоступна около 10 минут.<br><br> Потребуется обновление десктоп терминала.",
  },
  {
    date: "2022-06-23",
    title: "Новый сервис биржи АЕ: Котировка по запросу",
    content:
      "Маркет-мейкеры биржи АЕ предоставляют ликвидность в 8-12 страйках опционов в серии с покрытием диапазона дельты 0,75-0,05<br><br>" +
      "Однако, при значительных ценовых движениях базового актива может возникнуть потребность совершить сделку с опционами глубоко в деньгах, либо требуется объем значительно больший представленного в стакане - новый сервис Котировка по запросу поможет решить вопрос ликвидности. <br><br>" +
      "Отправьте запрос на мэйл client@ae.exchange с указанием точного тикера опциона, требуемого количества и направления котировки (бид или аск).<br><br>" +
      "Маркет-мейкеры биржи АЕ получат Ваш запрос и выставят требуемую котировку в стакан опциона<br><br>" +
      "Минимальный размер котировки по запросу 100 контрактов.",
  },
  {
    date: "2022-06-20",
    title: "Биржа АЕ снизила размер биржевой комиссии на 50%",
    content:
      "20 июня Биржа АЕ снизила размер биржевой комиссии на 50% по сделкам со всеми опционами и фьючерсами.<br><br> - BTC размер комиссии за сделку с опционом или фьючерсом теперь составляет 0,0002 токен АЕ <br><br> - ETH размер комиссии за сделку с опционом или фьючерсом теперь составляет 0,0001 токен АЕ <br><br> За сделки с токеном АЕ комиссия 0.",
  },
  {
    date: "2022-06-20",
    title: "Увеличена ликвидность в опционах на эфир",
    content:
      "Маркет-мейкеры биржи АЕ увеличили размеры котировок всех опционов на эфир #ETH в 4 раза, до 100 контрактов.",
  },
  {
    date: "2022-06-15",
    title: "Обновление веб терминала АЕ",
    content:
      "Доработаны процедуры рестарта веб сервера, при старте сервера производится проверка на активные ордера ботов и их отмена, что позволяет избежать дублей ордеров выставленных роботами<br>- Исправлена ошибка отображения shares собственного ордера в стакане котировок <br>- Оптимизированы шрифты веб терминала<br>- Доработана доска опционов исправлена ошибка сдвига колонок при отображении нескольких серий опционов<br>Веб терминал доступен всем клиентам основного контура биржи AE Trade",
  },
  {
    date: "2022-06-13",
    title: "В торговую систему биржи АЕ добавлены инструменты:",
    content:
      "- сентябрьский фьючерс на биткоин BTCU22.AE и опционы на него: месячная серия 29.07. <br>- сентябрьский фьючерс на эфир ETHU22.AE и опционы на него: месячная серия 29.07. <br>Маркет-мейкерами биржи обеспечивается ликвидность.",
  },
];
const newsEng: NewsItem[] = [
  {
    date: "2024-09-15",
    title: "Launch of the AE mobile application for all clients",
    content:
      "The AE application is a crypto derivatives trading terminal on your Android smartphone:<br><br>" +
      "- Options boards and an option strategy constructor with extensive modeling capabilities.<br><br>" +
      "- Trading robots (Delta hedger, Stairs grid and others) and manual trading in quotes glasses for futures and options.<br><br>" +
      "- Option combination charts on the AE account and price charts with orders and trades." +
      "Create option strategies and trade options on your phone everywhere!",
  },
  {
    date: "2024-07-30",
    title: "Trading of options on TON futures has started",
    content:
      "The contract size of 1 TON futures and option is 10 Toncoin.<br><br>" +
      "Margin requirements 30%.<br><br>" +
      "Commission: Maker 0.0005 AE, Taker 0.001 AE.<br><br>",
  },

  {
    date: "2024-06-04",
    title: "Subscription to the ready-made option strategy SPUTISOL is open",
    content:
      "The strategy is aimed at long-term profit through the constant sale of in-the-money put option premium and positive delta on the SOLANA futures.<br><br>" +
      "- Options: monthly series on the SOLANA futures<br><br>" +
      "- Strikes: in-the-money, delta 0.65-0.75<br><br>" +
      "- Longing: in the next monthly series a week before the expiration of the current series of options<br><br>" +
      "- Strategy risk: significant drop in the price of the SOL futures.<br><br>" +
      "Minimum subscription volume: 100 USDT.<br><br>",
  },
  {
    date: "2024-05-25",
    title: "Exchange competition: 'Best options strategy'.",
    content:
      "Show off your options trading skills on AE.<br><br>" +
      "Rules of participation:<br><br>" +
      "Post a screenshot of your options strategy in our chat, adding a short description with an answer to the question:<br><br>" +
      "Why do you think your strategy is the best on the market?<br><br>" +
      "The winner will be chosen by voting in the AE telegram channel.<br><br>" +
      "Prizes: The winner receives 5 AEP tokens to his account.<br><br>" +
      "Participate <a href='https://t.me/optionlab' >here</a><br><br>",
  },
  {
    date: "2024-05-23",
    title:
      "Subscription to the ReverWheel (Reversible Wheel) fund has been open since May 23, 2024 on the AE Cryptocurrency Options and Futures Exchange. ",
    content:
      "The fund consists of a portfolio of all crypto assets traded on the AE exchange, the fund's investments are equally distributed among the assets. <br><br>" +
      "The fund provides for investors to take positions in the prevailing trend with a leverage multiple of two, while collecting the decay of the time value of options.<br><br>",
  },
  {
    date: "2024-04-29",
    title:
      "Trading in SOLUSDT (SOLM24) futures and options has started on the AE crypto derivatives exchange",
    content:
      "Options and futures are now traded on the ae.exchange exchange <br><br>" +
      "- BITCOIN<br><br>" +
      "- Ethereum<br><br>" +
      "- Matic (Polygon) <br><br>" +
      "- DogeCoin <br><br>" +
      "- SOLANA<br><br>",
  },
  {
    date: "2024-01-05",
    title: "Subscription is open for the new fund BTC Wheel ATM (USDT)",
    content:
      "The fund uses a conservative investment strategy for Bitcoin - selling PUT ATM options for short periods.<br><br>" +
      "There is no commission charged when subscribing to the strategy, as well as when unsubscribing.<br><br>" +
      "More details about the new BTC Wheel ATM fund can be found in your AE Personal area.<br><br>",
  },
  {
    date: "2023-07-12",
    title: "Subscription to a new ready-made strategy SPUTICRYPTO is open",
    content:
      "A conservative investment strategy for a basket of cryptocurrencies takes advantage of selling ITM PUT options and getting Cash Flow from the collapse of their temporary premium.<br><br>" +
      "Options: sales of monthly series PUT options on futures in shares of BTC (40%) ETH (30%) DOGE (20%) MATIC (10%).<br><br>" +
      "When subscribing to a strategy, as well as when unsubscribing, no commission is charged.<br><br>" +
      "More details about the new ready-made strategy can be found in the Personal area.<br><br>",
  },
  {
    date: "2023-05-06",
    title: "Major update of the AE web terminal",
    content:
      "New graphic library. Stock Chart with support for adding graphic elements, technical analysis indicators, changing the display type (Candlestick, HLC, Line, etc...).<br><br>" +
      "New PnL charts for option strategies. Added display of tickers and the amount, size of required GM margin requirements. Corrections made.<br><br>" +
      "Added new functionality to the message system: support for sending attachments and the ability to edit your messages.<br><br>" +
      "The new trading robot Trend. Earns on directional price movements. Calculates profit and loss on its transactions. Closes the position when the specified profit or maximum risk is reached.<br><br>" +
      "Identified bugs have been fixed.<br><br>",
  },
  {
    date: "2023-02-23",
    title: "Ready-made AE strategies without commission.",
    content:
      "- Subscription fees for all ready-made AE strategies have been cancelled.<br><br>",
  },
  {
    date: "2023-01-31",
    title:
      "AE Exchange has begun providing access to customers with its AE Exchange API Gateway.",
    content:
      "- Access to API AE is provided for connecting external trading systems and own software to the trading system of the AE exchange. In the section of the AE site Documents of the Exchange, the Specification of the Client API Websocket connection protocol and an Example for connecting to the AE Game test server<br><br>",
  },
  {
    date: "2022-12-24",
    title:
      "New AE web terminal functionality allows you to create your own trading robot.",
    content:
      "- The new Custom bot has the ability to create your own trading robot. <br><br>The robot algorithm is described by scripts that have the syntax of Java/JavaScript languages. <br><br>Watch the short video <a href='https:/ /youtu.be/tLtrLSQfm98'>Youtube channel</a><br><br>",
  },
  {
    date: "2022-11-16",
    title: "Dogecoin (DOGE) Options and Futures Trading Launched",
    content:
      "- Quarterly futures, the size of one contract is 1000 DOGE coins. December futures DOGEZ22 is available<br><br>" +
      "- DOGE GM futures margin requirement is 30%<br><br>" +
      "- Monthly and quarterly options. Option series available on 12/30/2022<br><br>" +
      "- MM in options 2+7 strikes. Strike step 10<br><br>" +
      "For detailed information on new contracts, see Exchange Documents<br><br>",
  },
  {
    date: "2022-10-25",
    title:
      "Trading in options and futures on the MATIC coin of the POLIGON project has started",
    content:
      "- Quarterly futures, the size of one contract is 100 MATIC coins. December futures MATICZ22 is available<br><br>" +
      "- MATIC GM futures margin requirement is 25%<br><br>" +
      "- Options of monthly and quarterly series. Current available option series on 25.11 and 30.12.2022<br><br>" +
      "- MM in options 2+7 strikes<br><br>",
  },
  {
    date: "2022-08-30",
    title: "Updating AE Web Terminal",
    content:
      "Major update of AE web terminal, major improvements and fixes:<br><br>" +
      "- Calculation of GM margin requirements in the options board in Offline mode for given values of the futures price and IV options has been made. Such a calculation is necessary when modeling option strategies.<br><br>" +
      "- Optimized the quality of connection with the server.<br><br>" +
      "- Saved all open option series in the options board.<br><br>" +
      "- Fixed error displaying the number of contracts of their orders in the order book and Orders.<br><br>" +
      "- Fixed bugs in option-MM bot logic.<br><br>" +
      "- Eliminated delay in price calculation and Greeks after selecting a new series in the options board.<br><br>" +
      "- Fixed a bug with duplicate orders of the Stairs bot during a daily restart of the web server.<br><br>" +
      "- Added a Cancel All button to the Orders window to cancel all active orders for the account.<br><br>",
  },
  {
    date: "2022-08-23",
    title:
      "New Ready Strategy for Miners: Insurance Against Bitcoin Price Drop",
    content:
      "New ready-made AE Strategy for miners: HedgeBTC - a strategy for hedging against a strong fall in bitcoin by buying put options of a monthly series gives a maximum x20 exposure of the leverage of BTC short futures.<br> Available for subscription in the AE Trade Dashboard.<br>< br>",
  },
  {
    date: "2022-08-10",
    title: "Increased partner rewards up to 40%",
    content:
      "According to the wishes of the members of our community, the value of the AE Exchange Partner Reward has been increased to 40%.<br><br>",
  },
  {
    date: "2022-08-09",
    title: "BuyIVBTC New Strategy AE",
    content:
      "Buy IV BTC strategy for buying IV options on bitcoin futures, makes a profit when the volatility of IV options increases and the realizable volatility of RV bitcoin futures increases.<br><br>",
  },
  {
    date: "2022-08-02",
    title: "AE New Strategy: Selling SIVBTC Volatility",
    content:
      "Subscription to SIVBTC Strategy is available in AE STORE: <br><br>" +
      "Sell IV BTC strategy for selling IV options on bitcoin futures, profits from the decrease in the volatility of the IV options, as well as from the temporary decay of the sold options. <br><br>",
  },
  {
    date: "2022-07-22",
    title: "SPUTIETH new product of the Strategy line",
    content:
      "Launched a new product in the SPUTIETH Strategies line - selling in-the-money put options on ETH futures.<br><br>" +
      "Starting price 1 USDT. Available for subscription in the AE STORE section of the AE exchange client's Personal Account.",
  },
  {
    date: "2022-07-08",
    title: "Launch of a new class of products - Strategies",
    content:
      "AE Exchange has launched a new class of Strategies products, this is a line of popular ready-made option strategies for obtaining financial results for which it is enough to simply subscribe. <br><br>" +
      "The first new product of the line was SPUTIBTC. <br><br>" +
      "SPUTIBTC - option strategy sold put in money ( Sold Put ITM BTC ) on bitcoin futures. The strategy is constantly extended for the next option series. The price changes daily and is available both in the Personal Account and in trading terminals. SPUTIBTC is available for subscription in the section AE STORE Personal account of the client of the AE exchange.",
  },
  {
    date: "2022-06-30",
    title: "AE Trade Trading System Update",
    content:
      "At 12:00 Moscow time, the AE Trade trading system will be updated, the trading system will be unavailable for about 10 minutes.<br><br> The desktop terminal will need to be updated.",
  },
  {
    date: "2022-06-23",
    title: "AE Exchange New Service:Quote on Demand",
    content:
      "AE exchange market makers provide liquidity in 8-12 option strikes in a series covering a delta range of 0.75-0.05<br><br>" +
      "However, in case of significant price movements of the underlying asset, it may be necessary to make a deep-in-the-money option trade, or a volume much larger than that presented in the order book is required - the new Quote on Demand service will help solve the liquidity issue. <br><br>" +
      "Send a request to client@ae.exchange specifying the exact ticker of the option, the required quantity and the direction of the quote (bid or ask).<br><br>" +
      "Market makers of the AE exchange will receive your request and put the required quote in the order book<br><br>" +
      "Minimum quote size upon request is 100 contracts.",
  },
  {
    date: "2022-06-20",
    title: "AE Exchange Reduced Exchange Commission by 50%",
    content:
      "On June 20, the AE Exchange reduced the exchange commission by 50% for transactions with all options and futures.<br><br> - BTC commission for a transaction with an option or future is now 0.0002 AE tokens <br><br > - ETH commission for a trade with an option or future is now 0.0001 AE token <br><br> For transactions with an AE token, the commission is 0.",
  },
  {
    date: "2022-06-20",
    title: "Increased Liquidity in Ethereum Options",
    content:
      "Market makers of the AE exchange increased the quote sizes of all #ETH Ethereum options by 4 times, up to 100 contracts.",
  },
  {
    date: "2022-06-15",
    title: "Updating AE Web Terminal",
    content:
      "Improved the procedures for restarting the web server, when the server starts, it checks for active bot orders and cancels them, which allows you to avoid duplicate orders placed by robots<br>- Fixed display of shares of your own order in the order book <br>- Optimized web terminal fonts <br>- Improved options board, fixed column shift error when displaying several series of options<br>The web terminal is available to all clients of the main contour of the AE Trade exchange",
  },
  {
    date: "2022-06-13",
    title: "Tools added to AE Exchange trading system:",
    content:
      "- September bitcoin futures BTCU22.AE and options on it: monthly series 29.07. <br>- September futures on ether ETHU22.AE and options on it: monthly series 07.29. <br>Market makers of the exchange provide liquidity. ",
  },
];
class News {
  dt: string[] = [
    "",
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];
  dtEng: string[] = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  monthNamesRus: string[] = [
    "",
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  monthNamesEng: string[] = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  constructor() {
    makeAutoObservable(this);
  }
  getDtString = (date: string) => {
    let starr = date.split("-");
    return starr[2] + " " + this.dt[+starr[1]];
  };
  getDtStringEng = (date: string) => {
    let starr = date.split("-");
    return starr[2] + " " + this.dtEng[+starr[1]];
  };
  getLastNews = (num: number) => {
    return news.slice(0, num);
  };
  getAllNews = () => {
    return news;
  };
  getLastNewsEng = (num: number) => {
    return newsEng.slice(0, num);
  };
  getAllNewsEng = () => {
    return newsEng;
  };
}

export default new News();
