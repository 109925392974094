import React, { useEffect, useState } from "react";
import "./Article.scss";
import axios from "axios";
import { Pagination } from "antd";
import type { PaginationProps } from "antd";
interface Article {
  Title: string;
  Author: string;
  Content: string;
  CreateAt: string;
  Uuid: string;
}
const ArticlesList = () => {
  const [articles, setArticles] = useState<Article[] | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  useEffect(() => {
    LoadPage(1);
  }, []);
  const onChangePage: PaginationProps["onChange"] = (page) => {
    console.log(page);
    LoadPage(page);
  };
  const LoadPage = (page: number) => {
    axios
      .get("https://ae.exchange/lktrade/blog/published/8/" + page)
      .then(function (res) {
        setArticles(res.data.Articles);
        setPage(res.data.Page);
        setTotal(res.data.Total);
        console.log(total);
      });
  };
  if (articles === undefined) {
    return <></>;
  }
  return (
    <>
      <div className="page_content_left">
        <ArticlePreview article={articles[0]} />
        <ArticlePreview article={articles[2]} />
        <ArticlePreview article={articles[4]} />
        <ArticlePreview article={articles[6]} />
      </div>
      <div className="page_content_vline"></div>

      <div className="page_content_right">
        <ArticlePreview article={articles[1]} />
        <ArticlePreview article={articles[3]} />
        <ArticlePreview article={articles[5]} />
        <ArticlePreview article={articles[7]}/>
        <Pagination
          simple
          defaultCurrent={page}
          total={total}
          onChange={onChangePage}
          defaultPageSize={8}
        />
      </div>
    </>
  );
};

export default ArticlesList;

const ArticlePreview = ({ article }: { article: Article | undefined }) => {
  if (article === undefined) {
    return <></>;
  } else {
    const dt = new Date(article.CreateAt);
    return (
      <div className="preview_article">
        <div className="view_article_date">
          {dt.toLocaleDateString("ru-Ru")}
        </div>
        <div className="view_article_title">{article.Title}</div>
        <div
          className="view_article_content"
          dangerouslySetInnerHTML={{ __html: article.Content }}
        ></div>
        <a
          className="full_view"
          href={"http://ae.exchange/blog/" + article.Uuid}
        >
          {" "}
          <div className="soc_p_link">
            {" "}
            Читать полностью{" "}
            <svg
              style={{ paddingLeft: "15px", marginBottom: "-2px" }}
              width="25"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <path
                d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                stroke="#E82862"
                strokeWidth="1.5"
              />
            </svg>{" "}
          </div>{" "}
        </a>
      </div>
    );
  }
};
