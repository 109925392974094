import React from "react";
import { observer } from "mobx-react-lite";
import "./FuturesInfoBlock.scss";
import storeInstruments from "../../Store/Instruments";
const FuturesInfoBlock = observer(() => {
  let inst = storeInstruments.getInstruments();
  const ClickFutureHandler=(event:React.MouseEvent<HTMLDivElement>)=>{
    event.preventDefault();
    const div:HTMLDivElement=event.currentTarget
    const ticker=div.title
    console.log(ticker)
    storeInstruments.setSelectedFuture(ticker);
  }
  const items = inst.map((item) => {
    return(<div key={item.Ticker} onClick={ClickFutureHandler} title={item.Ticker}><span className={item.Iconame} style={{fontSize: item.Ticker===storeInstruments.SelectedFuture?'170%':'100%', cursor:"zoom-in"}}>{item.Ticker}</span> <span className={item.Direction}>{item.Last}</span></div>)
  })
  return <div id="futuresinfoblock" style={{color:"#fff"}}><div id="futuresblock">{items}</div></div>;
});

export default FuturesInfoBlock;
