import React, { useState } from "react";
import { Drawer } from "antd";
import Logo from "../Logo/Logo";
import LangSelector from "../LangSelector/LangSelector";
import MenuRight from "../MenuRight/MenuRight";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import FuturesInfoBlock from  "../FuturesInfoBlock/FuturesInfoBlock"
import "./Header.scss";
const HeaderEng = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <FuturesInfoBlock></FuturesInfoBlock>
      <div className="header">
        <div className="header_block ">
          <MenuUnfoldOutlined
            className="header_block_menu"
            onClick={showDrawer}
          />
          <Drawer placement="left" onClose={onClose} open={open}>
            <MenuRight></MenuRight>
          </Drawer>
          <div className="header_block_left">
            <Logo />
          </div>
          <div className="header_block_right">
            <LangSelector></LangSelector>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderEng;
