import React, { useState } from "react";
import "./News.scss";
import Header from "../../Components/Header/Header";
import ImageChartMenu from "../../Components/ImageChartMenu/ImageChartMenu";
import Footer from "../../Components/rus/Footer/Footer";
import newsStor from "../../Store/News";
import defStor from "../../Store/Default"
import { observer } from "mobx-react-lite";

const News = observer(() => {
  const [selectedNews, setSelectedNews] = useState(0);
  if(defStor.getLang()===2){
    const news = newsStor.getAllNewsEng();
    const lastNews = news[0];
    const lastNewsMonth: number = +lastNews.date.split("-")[1];
    const handleMouseClic = (e: any, i: number) => {
      console.log(e);
      setSelectedNews(i);
    };
  
    const newsList = news.map((item, i) => {
      return (
        <div
          key={item.title + i}
          className="wn_block"
          onClick={(e) => handleMouseClic(e, i)}
        >
          <div className={i === selectedNews ? "selected_news" : ""}>
            <div className="wn_block_date" >
              {newsStor.getDtStringEng(item.date)}
            </div>
            <div className="wn_block_title" dangerouslySetInnerHTML={{ __html: item.title }}></div>
          </div>
        </div>
      );
    });
    return (
      <div className="body">
        <Header />
  
        <ImageChartMenu
          titleImg="AE exchange news"
          textImg="Important events and announcements"
        />
  
        <div className="page_content">
          <div className="page_content_left">
            <div className="onbidding_h_content">
              {newsStor.monthNamesEng[lastNewsMonth]}
              <svg
                style={{ margin: "17px 0 0 10px" }}
                width="14"
                height="18"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 8L13.0622 0.5H0.937822L7 8Z" fill="#E82862" />
              </svg>
            </div>
            <div className="onbidding_text_content" style={{ marginTop: "30px" }}>
              {newsList}
            </div>
          </div>
          <div className="page_content_vline"></div>
          <div className="page_content_right">
            <div className="onbidding_text_content" style={{ marginTop: "30px" }}>
              <div
                className="news_content_title"
                dangerouslySetInnerHTML={{ __html: news[selectedNews].title }}
              ></div>
              <div
                className="news_content"
                dangerouslySetInnerHTML={{ __html: news[selectedNews].content }}
              ></div>
            </div>
          </div>
        </div>
        <div className="page_footer">
          <Footer />
        </div>
      </div>
    );
  }

  const news = newsStor.getAllNews();
  const lastNews = news[0];
  const lastNewsMonth: number = +lastNews.date.split("-")[1];
  const handleMouseClic = (e: any, i: number) => {
    console.log(e);
    setSelectedNews(i);
  };

  const newsList = news.map((item, i) => {
    return (
      <div
        key={item.title + i}
        className="wn_block"
        onClick={(e) => handleMouseClic(e, i)}
      >
        <div className={i === selectedNews ? "selected_news" : ""}>
          <div className="wn_block_date" >
            {newsStor.getDtString(item.date)}
          </div>
          <div className="wn_block_title" dangerouslySetInnerHTML={{ __html: item.title }}></div>
        </div>
      </div>
    );
  });
  return (
    <div className="body">
      <Header />

      <ImageChartMenu
        titleImg="Новости биржи АЕ"
        textImg="Важные события и объявления"
      />

      <div className="page_content">
        <div className="page_content_left">
          <div className="onbidding_h_content">
            {newsStor.monthNamesRus[lastNewsMonth]}
            <svg
              style={{ margin: "17px 0 0 10px" }}
              width="14"
              height="18"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 8L13.0622 0.5H0.937822L7 8Z" fill="#E82862" />
            </svg>
          </div>
          <div className="onbidding_text_content" style={{ marginTop: "30px" }}>
            {newsList}
          </div>
        </div>
        <div className="page_content_vline"></div>
        <div className="page_content_right">
          <div className="onbidding_text_content" style={{ marginTop: "30px" }}>
            <div
              className="news_content_title"
              dangerouslySetInnerHTML={{ __html: news[selectedNews].title }}
            ></div>
            <div
              className="news_content"
              dangerouslySetInnerHTML={{ __html: news[selectedNews].content }}
            ></div>
          </div>
        </div>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
});

export default News;
