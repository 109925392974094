import React from "react"
import axios from 'axios'
import { useSignIn } from 'react-auth-kit'
import { Form, Input, Button, Checkbox } from 'antd';
import {useNavigate} from 'react-router-dom'
import 'antd/dist/dark-theme'
import {Md5} from "ts-md5";

const SignInComponent = () => {
    const signIn = useSignIn()
   // const [formData, setFormData] = React.useState({email: '', password: ''})
   let navigate = useNavigate();
   const salt = "$1$ae_salt";
    const onFinish = (values: any) => {
        console.log('Success:', values);
        onSubmit(values)
      };
    
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
    const onSubmit = (values: any) => {
        console.log('Success:', values);
        values.pass= Md5.hashAsciiStr(values.password + salt);
       
    //    e.preventDefault()
        axios.post('https://ae.exchange/lktrade}}/users/loginUser', values)
            .then((res)=>{
                if(res.status === 200 && res.data.code===0){
                    if(signIn({token: res.data.data.Token,
                               expiresIn:100,
                               tokenType: "Bearer",
                               authState: res.data.data,
                               })){ // Only if you are using refreshToken feature
                        // Redirect or do-something

                    }else {
                        //Throw error
                    }
                    navigate('/flex')
                }
            })
    }

    return (
      //  <form onSubmit={onSubmit}>
       //     <input type={"email"} onChange={(e)=>setFormData({...formData, email: e.target.value})}/>
     //       <input type={"password"} onChange={(e)=>setFormData({...formData, password: e.target.value})}/>
//
   //         <button>Submit</button>
    //    </form>
        <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="login"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
  
        <Form.Item  valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
  
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
}
export default SignInComponent