import React from "react";
import "./Articles.scss";
import Header from "../../Components/Header/Header";
import ImageChartMenu from "../../Components/ImageChartMenu/ImageChartMenu";
import Footer from "../../Components/rus/Footer/Footer";
import ArticlesList from "../../Components/Articles/ArticlesList"

import storDef from "../../Store/Default"
import { observer } from "mobx-react-lite";

const Articles = observer(() => {
  console.log("index load")
  const imgRus={
    titleImg:"Биржа крипто деривативов АЕ:",
     textImg:"+ Лучший софт для трейдинга опционами<br />+ Готовые торговые роботы и создание собственных <br />+ Широкая линейка опционов и фьючерсов на криптовалюты  <br />+ Все расчеты в USDT, кросс маржирование, низкий риск"
  }
  const imgEng={
    titleImg:"AE Crypto Derivatives Exchange:",
     textImg:"+ The best options trading software <br>+ Ready-made trading robots and creating your own<br>+ A wide range of options and futures for cryptocurrencies<br>+ All settlements in USDT, cross margining, low risk"
  }
  let ls=storDef.getLang()
  let img=imgRus;
  if( ls===2){
    img=imgEng;
  }

  return (
    <div className="body">
      <Header />

      <ImageChartMenu titleImg={img.titleImg} textImg={img.textImg}/>
      

      <div className="page_content">
      <ArticlesList/>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
})

export default Articles;
