import React from "react";
import { Link } from "react-router-dom";
import faqStor from "../../../Store/FAQ";
import { observer } from "mobx-react-lite";
import "./WidgetFAQ.scss";
const WidgetFAQ = observer(() => {
  const faq = faqStor.getLastFAQ(3);
  const faqItems = faq.map((item) => {
    return (
      <div key={item.title}>
        <div className="ex_tytle">{item.title}</div>
        <div className="row" style={{ margin: "7px 0 15px 0" }}>
          <div className="ex_img">
            <svg
              width="20"
              height="2"
              viewBox="0 0 20 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 1H20" stroke="#E82862" strokeWidth="2" />
            </svg>
          </div>
          <div className="ex_text">{item.text}</div>
        </div>
      </div>
    );
  });
  return (
    <div className="wfaq_body">
      <div className="wfaq_header">{faqStor.getHeader()}</div>
      <div className="wfaq_title">{faqStor.getTitle()}</div>
      <div className="wfaq_body">{faqItems}</div>
      <Link to="/faq" style={{ textDecoration: "none" }}>
        <div className="row" style={{ marginTop: "34px" }}>
          <div className="wfaq_link_text">{faqStor.getLink()}</div>
          <div className="wfaq_link_img">
            <svg
              style={{ paddingLeft: "15px", marginBottom: "-1px" }}
              width="30"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                stroke="#E82862"
                strokeWidth="1.5"
              />
            </svg>
          </div>
        </div>
      </Link>
    </div>
  );
});

export default WidgetFAQ;
