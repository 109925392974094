import React from "react";
import "./Footer.scss";
import storeDef from "../../../Store/Default";
const Footer = () => {
  if (storeDef.getLang() === 2) {
    return (
      <div className="footer">
        <div className="fd1">
          <div className="fd2">
            <div className="soc_block">
              <div className="soc_title">Telegram //</div>
              <div className="soc_p_block">
                <div className="soc_p_img">
                  <img src="/images/telegram.png" />
                </div>
                <div className="soc_p_text">
                  Telegram channel.
                  <br />
                  Announcements and news
                  <a href="https://t.me/optionscrypto">
                    <div className="soc_p_link">
                      Go to Telegram
                      <svg
                        style={{ paddingLeft: "15px", marginBottom: "-2px" }}
                        width="25"
                        height="14"
                        viewBox="0 0 10 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                          stroke="#E82862"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="soc_block">
              <div className="soc_title">Youtube //</div>
              <div className="soc_p_block">
                <div className="soc_p_img">
                  <img src="/images/youtube.png" />
                </div>
                <div className="soc_p_text">
                  Our YouTube channel with instructions
                  <br />
                  and recordings of webinars
                  <a href="https://www.youtube.com/channel/UC_GS7IXMUqfuA4lQQnqlGxA?view_as=subscriber">
                    <div className="soc_p_link">
                      Watch the channel
                      <svg
                        style={{ paddingLeft: "15px", marginBottom: "-2px" }}
                        width="25"
                        height="14"
                        viewBox="0 0 10 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                          stroke="#E82862"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fl">
          <div className="soc_block">
            <div className="img_laft" style={{ paddingBottom: "20px" }}>
              <img alt="laft" src="/images/laft.png" />

              <svg
                style={{ paddingLeft: "40px" }}
                width="107"
                height="55"
                viewBox="0 0 107 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="1.5" cy="1.5" r="1.5" fill="#E0E0E0" />
                <circle cx="1.5" cy="27.5" r="1.5" fill="#E0E0E0" />
                <circle cx="1.5" cy="53.5" r="1.5" fill="#E0E0E0" />
                <circle cx="27.5" cy="1.5" r="1.5" fill="#E0E0E0" />
                <circle cx="27.5" cy="27.5" r="1.5" fill="#E0E0E0" />
                <circle cx="27.5" cy="53.5" r="1.5" fill="#E0E0E0" />
                <circle cx="53.5" cy="1.5" r="1.5" fill="#E0E0E0" />
                <circle cx="53.5" cy="27.5" r="1.5" fill="#E0E0E0" />
                <circle cx="53.5" cy="53.5" r="1.5" fill="#E0E0E0" />
                <circle cx="79.5" cy="1.5" r="1.5" fill="#E0E0E0" />
                <circle cx="79.5" cy="27.5" r="1.5" fill="#E0E0E0" />
                <circle cx="79.5" cy="53.5" r="1.5" fill="#E0E0E0" />
                <circle cx="105.5" cy="1.5" r="1.5" fill="#E0E0E0" />
                <circle cx="105.5" cy="27.5" r="1.5" fill="#E0E0E0" />
                <circle cx="105.5" cy="53.5" r="1.5" fill="#E0E0E0" />
              </svg>
            </div>
            When using materials
            <br />
            the site requires a link to www.laft.biz
            <br />
            <br />© 2023, Laboratory Advanced Financial Technologies (LAFT)
            <div className="fl_c"></div>
          </div>
          <div className="soc_block">
            <a href="/starttrade">
              <div className="fl_l">/ Start trading</div>
            </a>
            <a href="/pdocs">
              <div className="fl_l">/ Exchange Documents</div>
            </a>

            <a href="/onbidding">
              <div className="fl_l">/ General Provisions on Trading</div>
            </a>

            <a href="/faq">
              <div className="fl_l">/ FAQ</div>
            </a>
          </div>
          <div className="soc_block">
          <a href="/products">
              <div className="fl_l">/ Our Services</div>
            </a>
            <a href="https://laft.biz">
              <div className="fl_l" style={{ paddingTop: "23px" }}>
                / Laboratory Advanced Financial Tehnologies
              </div>
            </a>
            <div className="fl_l"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="footer">
      <div className="fd1">
        <div className="fd2">
          <div className="soc_block">
            <div className="soc_title">Telegram //</div>
            <div className="soc_p_block">
              <div className="soc_p_img">
                <img src="/images/telegram.png" />
              </div>
              <div className="soc_p_text">
                Присоединяйтесь к нашей группе в
                <br />
                Telegram и участвуйте в обсуждениях
                <a href="https://t.me/option_lab">
                  <div className="soc_p_link">
                    Перейти в Telegram
                    <svg
                      style={{ paddingLeft: "15px", marginBottom: "-2px" }}
                      width="25"
                      height="14"
                      viewBox="0 0 10 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                        stroke="#E82862"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="soc_block">
            <div className="soc_title">Youtube //</div>
            <div className="soc_p_block">
              <div className="soc_p_img">
                <img src="/images/youtube.png" />
              </div>
              <div className="soc_p_text">
                Наш Youtube-канал с инструкциями
                <br />и записями вебинаров
                <a href="https://www.youtube.com/channel/UCaT0EOA-ye8dGB5Z5WNn5bA?view_as=subscriber">
                  <div className="soc_p_link">
                    Смотреть канал
                    <svg
                      style={{ paddingLeft: "15px", marginBottom: "-2px" }}
                      width="25"
                      height="14"
                      viewBox="0 0 10 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                        stroke="#E82862"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="soc_block">
            <div className="soc_title">VK //</div>
            <div className="soc_p_block">
              <div className="soc_p_img">
                <img src="/images/vk.png" />
              </div>
              <div className="soc_p_text">
                Присоединяйтесь к нашей группе в
                <br />
                VK и участвуйте в обсуждениях
                <a href="https://vk.com/public211977639">
                  <div className="soc_p_link">
                    Перейти в VK
                    <svg
                      style={{ paddingLeft: "15px", marginBottom: "-2px" }}
                      width="25"
                      height="14"
                      viewBox="0 0 10 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 1L5 7L2 13H5L9 7L5 1H2Z"
                        stroke="#E82862"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fl">
        <div className="soc_block">
          <div className="img_laft" style={{ paddingBottom: "20px" }}>
            <img alt="laft" src="/images/laft.png" />

            <svg
              style={{ paddingLeft: "40px" }}
              width="107"
              height="55"
              viewBox="0 0 107 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#E0E0E0" />
              <circle cx="1.5" cy="27.5" r="1.5" fill="#E0E0E0" />
              <circle cx="1.5" cy="53.5" r="1.5" fill="#E0E0E0" />
              <circle cx="27.5" cy="1.5" r="1.5" fill="#E0E0E0" />
              <circle cx="27.5" cy="27.5" r="1.5" fill="#E0E0E0" />
              <circle cx="27.5" cy="53.5" r="1.5" fill="#E0E0E0" />
              <circle cx="53.5" cy="1.5" r="1.5" fill="#E0E0E0" />
              <circle cx="53.5" cy="27.5" r="1.5" fill="#E0E0E0" />
              <circle cx="53.5" cy="53.5" r="1.5" fill="#E0E0E0" />
              <circle cx="79.5" cy="1.5" r="1.5" fill="#E0E0E0" />
              <circle cx="79.5" cy="27.5" r="1.5" fill="#E0E0E0" />
              <circle cx="79.5" cy="53.5" r="1.5" fill="#E0E0E0" />
              <circle cx="105.5" cy="1.5" r="1.5" fill="#E0E0E0" />
              <circle cx="105.5" cy="27.5" r="1.5" fill="#E0E0E0" />
              <circle cx="105.5" cy="53.5" r="1.5" fill="#E0E0E0" />
            </svg>
          </div>
          При использовании материалов
          <br />
          сайта обязательна ссылка на ae.exchange
          <br />
          <br />© 2023, Laboratory Advanced Financial Technologies (LAFT)
        </div>
        <div className="soc_block">
          <a href="/starttrade">
            <div className="fl_l">/ Начать торговать на AE</div>
          </a>
          <a href="/pdocs">
            <div className="fl_l">/ Документы Биржи</div>
          </a>
          <a href="/onbidding">
            <div className="fl_l">/ Общие положения о торгах</div>
          </a>
          <a href="/faq">
            <div className="fl_l">/ Частые вопросы</div>
          </a>
          <a href="/products">
            <div className="fl_l">/ Наши сервисы</div>
          </a>
        </div>
        <div className="soc_block">
          <a href="https://laft.biz">
            <div className="fl_l" style={{ paddingTop: "23px" }}>
              / Laboratory Advanced Financial Tehnologies
            </div>
          </a>
          <div className="fl_l"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
