import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import type { MenuProps } from "antd";
import storDef from "../../Store/Default"
import "./LangSelector.scss"

const LangSelector = () => {
  let selL = localStorage.getItem("ae-lang-select");
   if (selL === null) {
    selL = "2";
  }
  let location = useLocation();
  let path = location.pathname.replace("/eng", "");
  let navi = useNavigate();
  const LangClick: MenuProps["onSelect"] = (e) => {
    // console.log('click ', e);
    localStorage.setItem("ae-lang-select", e.key);
    localStorage.setItem("auth.langSelect", (+e.key)-1+'');
    storDef.setLang(+e.key)
   /* if (e.key === "2") {
      path = "/eng" + path;
    }*/
    navi(path);
  };
  const menu = (
    <Menu
      selectable
      defaultSelectedKeys={[selL]}
      onSelect={LangClick}
      items={[
        {
          key: "2",
          label: (
            <div className="hearer_dropdown_lang_select_text row">
              <img
                style={{ width: "26px" }}
                height="18px"
                alt="eng"
                src="/images/ae_flag_uk.png"
              />
              <div
                style={{
                  display: "inline-block",
                  marginTop: "-4px",
                  marginLeft: "20px",
                }}
              >
                English
              </div>
            </div>
          ),
        },
        {
          key: "1",
          label: (
            <div className="hearer_dropdown_lang_select_text row">
              <img
                style={{ width: "26px" }}
                height="18px"
                alt="rus"
                src="/images/ae_flag_ru.png"
              />
              <div
                style={{
                  display: "inline-block",
                  marginTop: "-4px",
                  marginLeft: "20px",
                }}
              >
                Русский
              </div>
            </div>
          ),
        },
      ]}
    />
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
      <div className="lang_select">
        <div className="lang_select_img">
          {selL === "1" && <img alt="rus" src="/images/ae_flag_ru.png" />}
          {selL === "2" && <img alt="rus" src="/images/ae_flag_uk.png" />}
        </div>
        {selL === "1" && <div className="lang_select_text">Русский</div>}
        {selL === "2" && <div className="lang_select_text">English</div>}
        <div className="lang_select_btn">
          <svg width="15" height="15">
            <use xlinkHref="#select_item" />
          </svg>
        </div>
        <svg display="none">
          <symbol
            width="10"
            height="6"
            id="select_item"
            viewBox="0 0 10 6"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 6L9.33013 0.75H0.669873L5 6Z" />
          </symbol>
        </svg>
      </div>
    </Dropdown>
  );
};

export default LangSelector;
