import React from "react";
import Header from "../../Components/Header/Header";
import ImageChartMenu from "../../Components/ImageChartMenu/ImageChartMenu";
import Footer from "../../Components/rus/Footer/Footer";
import faqStor,{FAQItem} from "../../Store/FAQ";
import { observer } from "mobx-react-lite";
const Faq = observer(() => {
  const faqList = faqStor.getAllFAQ()

  const items = faqList.map((item:FAQItem, i) => {
    return (
      <div style={{maxWidth:"1440px"}} key={item.title+i}>
        <div className="ex_tytle">{item.title}</div>
        <div className="row" style={{margin:"7px 0 15px 0"}}>
          <div className="ex_img">
            <svg
              width="20"
              height="2"
              viewBox="0 0 20 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 1H20" stroke="#E82862" strokeWidth="2" />
            </svg>
          </div>
          <div className="ex_text">{ item.text }</div>
        </div>
      </div>
    );
  });

  return (
    <div className="body">
      <Header />

      <ImageChartMenu
        titleImg={faqStor.getTitleImg()}
        textImg=""
      />

      <div className="page_content">
      <div id="faq_page_content">
        {items}
      </div>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
});

export default Faq;
